import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MainView from "../views/MainView.vue";
import SubscriptionView from "../views/SubscriptionView.vue";
import Login from "../views/Auth/Login.vue";
import Register from "../views/Auth/Register.vue";
import AuthView from "../views/Auth/AuthView.vue";
import ViewSub from "../components/subscription/ViewSub.vue";
import CancelSub from "../components/subscription/CancelSub.vue";
import NotifySub from "../components/subscription/NotifySub.vue";
import ManageSubscription from "../components/subscription/ManageSubscription.vue";
import Tutorials from "../components/Tutorials.vue";
import DocumentDownload from "../components/DocumentDownload.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";

import AuthModule from "@/store/modules/Auth";
import { Storage } from "@/helpers";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: MainView,
  },
  {
    path: "/privacy-policy",
    name: "privacyPolicy",
    component: PrivacyPolicy,
  },
  {
    path: "/documents/:file",
    name: "documents",
    component: DocumentDownload,
  },
  {
    path: "/manage-subscription",
    meta: {
      requiresAuth: true,
    },
    name: "manageSubscription",
    component: ManageSubscription,
  },
  {
    path: "/tutorials",
    meta: {
      requiresAuth: true,
    },
    name: "tutorials",
    component: Tutorials,
  },
  {
    path: "/subscription",
    component: SubscriptionView,
    children: [
      {
        path: "view/:id",
        name: "viewSubscription",
        component: ViewSub,
      },
      {
        path: "terminated",
        name: "cancelSubscription",
        component: CancelSub,
      },
      {
        path: "information",
        name: "notifySubscription",
        component: NotifySub,
      },
    ],
  },
  {
    path: "/auth",
    component: AuthView,
    children: [
      {
        path: "login",
        name: "login",
        component: Login,
      },
      {
        path: "register",
        name: "register",
        component: Register,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // if (savedPosition) {
    //   return savedPosition;
    // }
    console.log("Scroll behaviour: ", to, from, savedPosition);
    if (to.hash) {
      // BEFORE:
      // return { selector: to.hash }
      // if (to.hash != from.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({
            selector: to.hash,
            offset: {
              x: 0,
              y: 150,
            },
            behavior: "smooth",
          });
        }, 200);
      });
    }
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  console.log("Routes: ", from, to);
  const requiresAuth = to.matched.some(
    (route) => route.meta && route.meta.requiresAuth
  );
  const requiresAuthFrom = from.matched.some(
    (route) => route.meta && route.meta.requiresAuth
  );
  const temp = Storage.getLogin();
  console.log("Temp", temp);
  if (
    temp == null ||
    temp.expiration == null ||
    temp.accessToken == null ||
    temp.username == null ||
    new Date(temp.expiration) <= new Date()
  ) {
    if (!requiresAuthFrom) {
      AuthModule.removeLoggedIn();
    }
  } else {
    AuthModule.setUserDetails(temp);
  }
  console.log("Auth required: ", requiresAuth);
  if (requiresAuth) {
    if (AuthModule.isLoggedIn) {
      next();
    } else {
      next({
        name: "login",
        query: {
          redirectedFrom: to.path,
        },
      });
    }
  } else {
    if (
      AuthModule.isLoggedIn &&
      (to.name == "login" || to.name == "register")
    ) {
      next({
        name: "home",
      });
    } else {
      next();
    }
  }
});

export default router;
