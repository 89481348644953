import { UserDetailsResponse } from "models";

const Storage = () => {
  const key = "zero-eight-web/login";
  const setLogin = (store: UserDetailsResponse) => {
    localStorage.setItem(key, JSON.stringify(store));
  };
  const getLogin = (): null | UserDetailsResponse => {
    const temp = localStorage.getItem(key);
    if (temp == null) return null;
    return JSON.parse(temp);
  };
  const removeLogin = () => {
    localStorage.removeItem(key);
  };

  return {
    setLogin,
    getLogin,
    removeLogin,
  };
};
export default Storage;
