<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="auto"
        ><div class="text-uppercase massiveHeader mb-5">Services</div></v-col
      >
    </v-row>
    <v-row justify="center" align="stretch">
      <v-col cols="12" md="4"
        ><v-card min-height="300px" height="100%" color="#1660A8">
          <v-container>
            <v-row justify="center">
              <v-col cols="auto">
                <v-icon
                  size="100"
                  color="white"
                  class="rounded-circle pa-3"
                  style="border: 2px solid white"
                  >mdi-database</v-icon
                >
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10"
                ><div class="white--text text-h2 text-center">
                  Create your own database
                </div></v-col
              >
              <v-col cols="11"
                ><div class="white--text text-h4 text-center">
                  With the Zero Eight online platform you create an environment
                  unique to your security company. User-friendly cloud-based
                  software allows your users to administer firearm compliance
                  without the need for intense technical training or plugging in
                  of additional hardware devices.
                </div></v-col
              >
            </v-row>
          </v-container>
        </v-card></v-col
      >
      <v-col cols="12" md="4"
        ><v-card min-height="300px" height="100%" color="#1660A8">
          <v-container>
            <v-row justify="center">
              <v-col cols="auto">
                <v-icon
                  size="80"
                  color="white"
                  class="rounded-circle pa-5"
                  style="border: 2px solid white"
                  >mdi-account-group</v-icon
                >
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10"
                ><div class="white--text text-h2 text-center">
                  Dedicated team of Firearm Compliance Professionals
                </div></v-col
              >
              <v-col cols="11"
                ><div class="white--text text-h4 text-center">
                  Give your security company the online support that it needs in
                  this digital age. Access your compliance information from
                  anywhere at anytime. Your information is available to you
                  within seconds, allowing you to make decisions on the go with
                  the peace of mind that the information is correct
                </div></v-col
              >
            </v-row>
          </v-container>
        </v-card></v-col
      ><v-col cols="12" md="4"
        ><v-card min-height="300px" height="100%" color="#1660A8">
          <v-container>
            <v-row justify="center">
              <v-col cols="auto">
                <v-icon
                  size="90"
                  color="white"
                  class="rounded-circle pa-4"
                  style="border: 2px solid white"
                  >mdi-office-building</v-icon
                >
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10"
                ><div class="white--text text-h2 text-center">
                  A Platform that Supports your Business
                </div></v-col
              >
              <v-col cols="11"
                ><div class="white--text text-h4 text-center">
                  With the Zero Eight online platform you create an environment
                  unique to your security company. User-friendly cloud-based
                  software allows your users to administer firearm compliance
                  without the need for intense technical training or plugging in
                  of additional hardware devices.
                </div></v-col
              >
            </v-row>
          </v-container>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class Services extends Vue {}
</script>
