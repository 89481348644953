<template>
  <v-container fluid>
    <v-row justify="center" align="center">
      <v-col cols="12" lg="9" class="screenshotsContainer">
        <v-slide-group
          v-model="slideshow"
          center-active
          class="screenshots"
          active-class="viewScreenshot"
          show-arrows
        >
          <v-slide-item v-for="(n, i) in images" :key="i" v-slot="{ toggle }">
            <v-card
              :ripple="false"
              flat
              class="ma-4 screenshot"
              height="auto"
              :width="130"
              @click="toggle"
            >
              <v-row dense class="fill-height" align="center" justify="center">
                <div
                  class="monitor-bg grey darken-4 rounded-lg pa-4 mx-auto"
                  style="height: auto; width: auto; max-width: 100%"
                >
                  <v-img
                    contain
                    max-width="100%"
                    height="100%"
                    class="text-center"
                    :src="n.pathLong"
                  ></v-img>
                </div>
              </v-row>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="10" md="5">
        <div class="massiveHeader">ABOUT US</div>
        <div class="text-h3 mb-3">
          When we first developed Zero Eight online platform, we had a simple
          vision
        </div>
        <ul>
          <li>
            To add value and help our clients support and grow their business.
          </li>
          <li>
            With firearm ownership being under constant scrutiny, we wanted to
            assist the industry by giving it an easy-to-use online platform to
            manage firearm compliance.
          </li>
          <li>
            Our motto is “Defiance through Compliance” which means that if we as
            an industry comply to the Firearms Control Act, government and
            anti-gun lobby groups have less Ammunition to use against us
          </li>
          <li>
            We truly believe that education and application of correct firearm
            compliance will naturally lead to a more professional and effective
            Security industry.
          </li>
        </ul>
      </v-col>
      <v-col cols="10" md="5">
        <div class="massiveHeader text-uppercase">OUR HERITAGE</div>
        <div class="text-body-1">
          Our Managing Director and Founder has a wealth of experience in law
          enforcement, private security and firearms instruction. This
          experience has been transferred into Zero Eight along with his passion
          for wanting to transform South Africa’s private security industry into
          a professional and compliant Industry that becomes the best in the
          world bar none.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class AboutUs extends Vue {
  slideshow = 0;
  images: any = [];

  mounted() {
    this.importAll(require.context("@/assets/screenshots/", false));
  }
  importAll(r: any) {
    r.keys().forEach((key: any) =>
      this.images.push({ pathLong: r(key), pathShort: key })
    );
  }
}
</script>

<style lang="scss">
.screenshots {
  .v-slide-group__content {
    justify-content: center;
  }
  .v-card--link:focus:before {
    opacity: 0;
  }
}
.screenshotsContainer {
  min-height: 420px;
}
.screenshot {
  transition: width 0.2s;
  transition-delay: 0.2s;
}
.viewScreenshot {
  transition: width 0.2s;
  width: 190px !important;
}
</style>
