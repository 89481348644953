<template>
  <v-container fluid>
    <v-row justify="center" class="my-8">
      <v-col cols="auto"
        ><div class="text-uppercase text-h2">
          Find a collection of tutorials below
        </div></v-col
      >
    </v-row>
    <v-row align="stretch" justify="center">
      <v-col cols="6" md="5">
        <v-card height="100%" class="py-4">
          <v-container>
            <v-row justify="center">
              <v-col cols="auto">
                <v-icon
                  class="bg-icon-testimonials pa-3"
                  color="accent"
                  size="70"
                  >mdi-binoculars</v-icon
                >
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="11">
                <div class="text-h6 text-uppercase text-center">Our vision</div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="11">
                <div class="text-body-1 text-center">
                  To protect South Africa’s Private Security Industry and doing
                  our bit in the fight against crime by becoming the preferred
                  Compliance Partner
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="6" md="5">
        <v-card height="100%" class="py-4">
          <v-container>
            <v-row justify="center">
              <v-col cols="auto">
                <v-icon
                  class="bg-icon-testimonials pa-3"
                  color="accent"
                  size="70"
                  >mdi-bullseye-arrow</v-icon
                >
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="11">
                <div class="text-h6 text-uppercase text-center">
                  Our mission
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="11">
                <div class="text-body-1 text-center">
                  To keep our Armed Security Professionals safe through
                  Compliance
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" sm="10">
        <v-data-iterator :items="tutorials" item-key="name">
          <template v-slot:default="{ items }">
            <v-row>
              <v-col
                v-for="item in items"
                :key="item.name"
                cols="12"
                md="6"
                lg="4"
              >
                <v-card>
                  <v-card-title>
                    <h4>{{ item.name }}</h4>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text class="text-center">
                    <iframe width="90%" height="300" :src="item.link"> </iframe>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-data-iterator>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Tutorials extends Vue {
  tutorials = [
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 1",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 2",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 3",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 4",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 5",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 6",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 7",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 8",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 9",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 10",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 11",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 12",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 13",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 14",
    },
    {
      link: "https://www.youtube.com/embed/dQw4w9WgXcQ",
      name: "Learn Something 15",
    },
  ];
}
</script>
