import store from "@/store";

import {
  VuexModule,
  Module,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import Auth from "./Auth";

@Module({ dynamic: true, namespaced: true, name: "Navigation", store })
class Navigation extends VuexModule {
  sidebar = false;
  menuItems = [
    {
      title: "Home",
      path: "#home",
      icon: "mdi-home",
    },
    {
      title: "About Us",
      path: "#about-us",
      icon: "mdi-information-variant",
    },
    {
      title: "Features",
      path: "#features",
      icon: "mdi-star-circle-outline",
    },
    {
      title: "Pricing",
      path: "#pricing",
      icon: "mdi-currency-usd",
    },
    {
      title: "Tutorials",
      path: "tutorials",
      icon: "mdi-cast-education",
    },
    {
      title: "Subscription",
      path: "manageSubscription",
      icon: "mdi-account-credit-card",
    },
  ];
  get getMenuItems() {
    if (Auth.isLoggedIn == false) {
      return this.menuItems.filter(
        (e) =>
          e.path.includes("tutorials") == false &&
          e.path.toLowerCase().includes("subscription") == false
      );
    }
    return this.menuItems;
  }
  @Mutation
  setMenuItems(val: boolean) {
    this.sidebar = val;
  }

  get getSidebar() {
    return this.sidebar;
  }
  @Mutation
  setSideBar(val: boolean) {
    this.sidebar = val;
  }
}

export default getModule(Navigation);
