<template>
  <v-container class="white--text py-2" fluid>
    <v-row no-gutters>
      <v-col cols="auto">
        <v-alert
          color="error"
          dark
          transition="fade-transition"
          :min-width="250"
          icon="mdi-alert-circle"
          prominent
          v-model="error.open"
        >
          <div class="text-body-1">
            {{ error.message }}
          </div>
        </v-alert></v-col
      >
    </v-row>
    <v-row class="mt-0" justify="start" align="stretch">
      <v-col sm="6" md="4" cols="12" v-for="item in getPackages" :key="item.id">
        <v-card class="d-flex flex-column" height="100%">
          <v-card-title class="secondary justify-space-between text-uppercase">
            <div class="text-h2 white--text">
              {{ item.subscriptionName }}
            </div>
            <div
              class="text-h2 text-center white--text"
              style="line-height: 20px"
            >
              R{{ item.defaultCostPerUser * item.numberOfUsersAllowed }} <br />
              <span class="text-body-2">per month</span>
            </div>
          </v-card-title>
          <v-card-text class="py-2">
            <v-container>
              <v-row align="start">
                <v-col cols="6" class="text-left">
                  <div class="text-h6 text-decoration-underline my-3">
                    User counts
                  </div>
                  <div class="text-body-1 mb-2">
                    Users: {{ item.numberOfUsersAllowed }}
                  </div>
                  <div class="text-body-1 mb-2">
                    ARO: {{ item.numberOfArmedResponseOfficersAllowed }}
                  </div>
                  <div class="text-body-1 mb-2">
                    SO: {{ item.numberOfSecurityOfficersAllowed }}
                  </div>
                  <template
                    v-if="
                      item.issuePermits == true ||
                      item.mobileApp ||
                      item.reporting
                    "
                  >
                    <div class="text-h6 text-decoration-underline my-3">
                      Access...
                    </div>
                    <div class="text-body-1 mb-2" v-if="item.mobileApp == true">
                      Mobile Permit Application
                    </div>
                    <div class="text-body-1 mb-2" v-if="item.reporting == true">
                      Reporting
                    </div>
                    <div
                      class="text-body-1 mb-2"
                      v-if="item.issuePermits == true"
                    >
                      Issue Permits
                    </div>
                  </template>
                </v-col>
                <v-col cols="6" class="text-left"
                  ><template
                    v-if="
                      Object.entries(item).find(
                        ([key, value]) =>
                          key.toLowerCase().includes('manage') && value == true
                      ) != null
                    "
                  >
                    <div class="text-h6 text-decoration-underline my-3">
                      Permissions to manage
                    </div>
                    <div
                      class="text-body-1 mb-2"
                      v-if="item.manageAmmunition == true"
                    >
                      Ammunition
                    </div>
                    <div
                      class="text-body-1 mb-2"
                      v-if="item.manageArmedResponseOfficers == true"
                    >
                      Armed Response officers
                    </div>
                    <div
                      class="text-body-1 mb-2"
                      v-if="item.manageFirearms == true"
                    >
                      Firearms
                    </div>
                    <div
                      class="text-body-1 mb-2"
                      v-if="item.manageIncidents == true"
                    >
                      Incidents
                    </div>
                    <div
                      class="text-body-1 mb-2"
                      v-if="item.manageSecurityOfficers == true"
                    >
                      Security Officers
                    </div>
                    <div
                      class="text-body-1 mb-2"
                      v-if="item.manageTeams == true"
                    >
                      Teams
                    </div>
                  </template></v-col
                >
              </v-row>
            </v-container>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions
            v-if="
              getTrailRun == null ||
              getTrailRun?.status != SubStatusType.PAYFASTPASS
            "
          >
            <v-btn
              v-if="getSubscription == null"
              block
              color="secondary"
              @click="
                getLoggedIn
                  ? (promptPayfast = {
                      item: item,
                      open: true,
                    })
                  : $router.push({
                      name: 'login',
                    })
              "
              >Sign up</v-btn
            >
            <v-btn
              block
              color="secondary"
              disabled
              v-else-if="getSubscription.id == item.id"
              >Owned</v-btn
            >
            <v-btn
              block
              color="secondary"
              @click="changeTo = { open: true, item: item }"
              v-else
              >Change Package</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row justify="start" align="stretch">
      <v-col sm="6" md="4" cols="12" v-for="item in packages" :key="item.id">
        <v-card class="d-flex flex-column" height="100%">
          <v-card-title class="secondary justify-center text-uppercase">
            <div class="text-h2 white--text">
              {{ item.subscriptionName }}
            </div>
          </v-card-title>
          <v-card-text class="py-2">
            <div class="text-h1 text-center my-3" style="line-height: 25px">
              R{{ item.defaultCostPerUser * item.numberOfUsersAllowed }} <br />
              <span class="text-h3">per month</span>
            </div>
            <div class="text-caption text-center mb-2">
              {{ item.numberOfUsersAllowed }} users
            </div>
            <div class="text-caption text-center mb-2">
              {{ item.numberOfArmedResponseOfficersAllowed }} armed response
              officers
            </div>
            <div class="text-caption text-center mb-2">
              {{ item.numberOfSecurityOfficersAllowed }} security officers
            </div>

            <template
              v-if="
                Object.entries(item).find(
                  ([key, value]) =>
                    key.toLowerCase().includes('manage') && value == true
                ) != null
              "
            >
              <div class="text-h3 text-center text-decoration-underline my-3">
                Manage...
              </div>
              <div
                class="text-caption text-center mb-2"
                v-if="item.manageAmmunition == true"
              >
                Ammunition
              </div>
              <div
                class="text-caption text-center mb-2"
                v-if="item.manageArmedResponseOfficers == true"
              >
                Armed Response officers
              </div>
              <div
                class="text-caption text-center mb-2"
                v-if="item.manageFirearms == true"
              >
                Firearms
              </div>
              <div
                class="text-caption text-center mb-2"
                v-if="item.manageIncidents == true"
              >
                Incidents
              </div>
              <div
                class="text-caption text-center mb-2"
                v-if="item.manageSecurityOfficers == true"
              >
                Security Officers
              </div>
              <div
                class="text-caption text-center mb-2"
                v-if="item.manageTeams == true"
              >
                Teams
              </div>
            </template>
            <template
              v-if="
                item.issuePermits == true || item.mobileApp || item.reporting
              "
            >
              <div class="text-h3 text-center text-decoration-underline my-3">
                Access...
              </div>
              <div
                class="text-caption mb-2 text-center"
                v-if="item.mobileApp == true"
              >
                Mobile Permit Application
              </div>
              <div
                class="text-caption mb-2 text-center"
                v-if="item.reporting == true"
              >
                Reporting
              </div>
            </template>

            <div
              class="text-caption mb-2 text-center"
              v-if="item.issuePermits == true"
            >
              Issue Permits
            </div>
          </v-card-text>
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn
              v-if="getSubscription == null"
              block
              color="secondary"
              @click="
                promptPayfast = {
                  item: item,
                  open: true,
                }
              "
              >Sign up</v-btn
            >
            <v-btn
              block
              color="secondary"
              disabled
              v-else-if="getSubscription.id == item.id"
              >Owned</v-btn
            >
            <v-btn block color="secondary" v-else>Change Package</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row> -->
    <v-dialog
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      @scroll.stop="() => {}"
      scrollable
      v-model="changeTo.open"
      :max-width="700"
      :min-height="200"
      :min-width="200"
    >
      <v-card
        tile
        @scroll.stop="() => {}"
        color="white"
        class="d-flex flex-column black--text"
      >
        <v-card-title class="primary">
          <div class="text-h5 white--text">
            Confirm changes to your subscription
          </div>
        </v-card-title>
        <v-card-text class="py-6">
          <div class="text-body-1 black--text px-3">
            Changes will only take affect on your
            <strong>next billing month</strong>.
          </div>
          <v-container fluid>
            <v-row>
              <v-col cols="12"
                ><div class="black--text text-h6">
                  Review Feature Changes
                </div></v-col
              >
            </v-row>
            <v-row dense class="mt-4 secondary white--text">
              <v-col cols="6">
                <div class="text-h4">
                  Current: <br />
                  {{
                    getSubscription ? getSubscription.subscriptionName : "[x]"
                  }}
                </div></v-col
              >
              <v-col cols="6">
                <div class="text-h4">
                  Change to: <br />
                  {{ changeTo.item ? changeTo.item.subscriptionName : "[x]" }}
                </div></v-col
              >
            </v-row>
            <v-row dense>
              <v-col cols="12" class="py-1"
                ><div class="black--text text-h6 text-decoration-underline">
                  Pricing:
                </div></v-col
              >
              <v-col cols="6">
                <div class="black--text text-body-1">
                  {{
                    getSubscription
                      ? getSubscription.defaultCostPerUser *
                          getSubscription.numberOfUsersAllowed +
                        "(+ additional user costing)"
                      : "[x]"
                  }}
                </div></v-col
              >
              <v-col cols="6">
                <div class="black--text text-body-1">
                  {{
                    changeTo.item
                      ? changeTo.item.defaultCostPerUser *
                          changeTo.item.numberOfUsersAllowed +
                        "(+ additional user costing)"
                      : "[x]"
                  }}
                </div></v-col
              >
            </v-row>
            <v-row dense>
              <v-col cols="12" class="py-1"
                ><div class="black--text text-h6 text-decoration-underline">
                  Security Firm Admins:
                </div></v-col
              >
              <v-col cols="6">
                <div class="black--text text-body-1">
                  {{
                    getSubscription
                      ? getSubscription.numberOfUsersAllowed
                      : "[x]"
                  }}
                </div></v-col
              >
              <v-col cols="6">
                <div class="black--text text-body-1">
                  {{
                    changeTo.item ? changeTo.item.numberOfUsersAllowed : "[x]"
                  }}
                </div></v-col
              >
            </v-row>
            <v-row dense>
              <v-col cols="12" class="py-1"
                ><div class="black--text text-h6 text-decoration-underline">
                  Armed Response Officers:
                </div></v-col
              >
              <v-col cols="6">
                <div class="black--text text-body-1">
                  {{
                    getSubscription
                      ? getSubscription.numberOfArmedResponseOfficersAllowed
                      : "[x]"
                  }}
                </div></v-col
              >
              <v-col cols="6">
                <div class="black--text text-body-1">
                  {{
                    changeTo.item
                      ? changeTo.item.numberOfArmedResponseOfficersAllowed
                      : "[x]"
                  }}
                </div></v-col
              >
            </v-row>
            <v-row dense>
              <v-col cols="12" class="py-1"
                ><div class="black--text text-h6 text-decoration-underline">
                  Security Officers:
                </div></v-col
              >
              <v-col cols="6">
                <div class="black--text text-body-1">
                  {{
                    getSubscription
                      ? getSubscription.numberOfSecurityOfficersAllowed
                      : "[x]"
                  }}
                </div></v-col
              >
              <v-col cols="6">
                <div class="black--text text-body-1">
                  {{
                    changeTo.item
                      ? changeTo.item.numberOfSecurityOfficersAllowed
                      : "[x]"
                  }}
                </div></v-col
              >
            </v-row>
            <v-row
              dense
              v-if="getSubscription && changeTo.item"
              class="black--text"
            >
              <v-col cols="12" class="py-1"
                ><div class="text-h6 text-decoration-underline">
                  Access
                </div></v-col
              >
              <v-col cols="6">
                <template
                  v-if="
                    getSubscription.issuePermits == true ||
                    getSubscription.mobileApp ||
                    getSubscription.reporting
                  "
                >
                  <div
                    class="text-body-1 mb-2"
                    v-if="getSubscription.mobileApp == true"
                  >
                    Mobile Permit Application
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="getSubscription.reporting == true"
                  >
                    Reporting
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="getSubscription.issuePermits == true"
                  >
                    Issue Permits
                  </div>
                </template></v-col
              >
              <v-col cols="6">
                <template
                  v-if="
                    changeTo.item.issuePermits == true ||
                    changeTo.item.mobileApp ||
                    changeTo.item.reporting
                  "
                >
                  <div
                    class="text-body-1 mb-2"
                    v-if="changeTo.item.mobileApp == true"
                  >
                    Mobile Permit Application
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="changeTo.item.reporting == true"
                  >
                    Reporting
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="changeTo.item.issuePermits == true"
                  >
                    Issue Permits
                  </div>
                </template></v-col
              >
            </v-row>
            <v-row
              dense
              v-if="getSubscription && changeTo.item"
              class="black--text"
            >
              <v-col cols="12" class="py-1"
                ><div class="text-h6 text-decoration-underline">
                  Permissions to Manage
                </div></v-col
              >
              <v-col cols="6">
                <template
                  v-if="
                    Object.entries(getSubscription).find(
                      ([key, value]) =>
                        key.toLowerCase().includes('manage') && value == true
                    ) != null
                  "
                >
                  <div
                    class="text-body-1 mb-2"
                    v-if="getSubscription.manageAmmunition == true"
                  >
                    Ammunition
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="getSubscription.manageArmedResponseOfficers == true"
                  >
                    Armed Response officers
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="getSubscription.manageFirearms == true"
                  >
                    Firearms
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="getSubscription.manageIncidents == true"
                  >
                    Incidents
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="getSubscription.manageSecurityOfficers == true"
                  >
                    Security Officers
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="getSubscription.manageTeams == true"
                  >
                    Teams
                  </div>
                </template></v-col
              >
              <v-col cols="6"
                ><template
                  v-if="
                    Object.entries(changeTo.item).find(
                      ([key, value]) =>
                        key.toLowerCase().includes('manage') && value == true
                    ) != null
                  "
                >
                  <div
                    class="text-body-1 mb-2"
                    v-if="changeTo.item.manageAmmunition == true"
                  >
                    Ammunition
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="changeTo.item.manageArmedResponseOfficers == true"
                  >
                    Armed Response officers
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="changeTo.item.manageFirearms == true"
                  >
                    Firearms
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="changeTo.item.manageIncidents == true"
                  >
                    Incidents
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="changeTo.item.manageSecurityOfficers == true"
                  >
                    Security Officers
                  </div>
                  <div
                    class="text-body-1 mb-2"
                    v-if="changeTo.item.manageTeams == true"
                  >
                    Teams
                  </div>
                </template></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="justify-center align-center">
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="auto">
                <v-btn
                  outlined
                  width="150px"
                  color="error"
                  @click="changeTo.open = false"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="auto">
                <v-btn
                  :loading="loading"
                  width="200px"
                  color="primary"
                  @click="changeTo.item ? changePackage(changeTo.item.id) : ''"
                  >Continue</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay v-model="promptPayfast.open">
      <v-card
        color="white"
        class="d-flex flex-column"
        :max-width="700"
        :min-height="200"
      >
        <v-card-title class="primary">
          <div class="text-h5">Continue to payfast?</div>
        </v-card-title>
        <v-card-text class="py-6">
          <div class="text-body-1 black--text px-3">
            You will be redirected to PayFast to continue with the purchase.
          </div>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="justify-center align-center">
          <v-container fluid>
            <v-row justify="center">
              <v-col cols="auto"
                ><v-btn
                  outlined
                  width="150px"
                  color="error"
                  @click="promptPayfast.open = false"
                  >Cancel</v-btn
                ></v-col
              >
              <v-col cols="auto"
                ><v-btn
                  :loading="loading"
                  width="200px"
                  color="primary"
                  @click="redirectToPayfast"
                  >Continue</v-btn
                ></v-col
              >
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-overlay>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import { axiosInstance } from "@/plugins/axios";
import { UserDetailsResponse, userRole } from "models";
import { subscription } from "subscriptionModels";
import AuthModule from "@/store/modules/Auth";
import SubscriptionModule from "@/store/modules/Subscription";
import { Subscription } from "@/helpers";
import axios, { AxiosError } from "axios";
import { SubscriptionTrialStatus } from "@/enum/trialRun";

@Component
export default class Prices extends Vue {
  SubStatusType = SubscriptionTrialStatus;
  error = {
    open: false,
    message: "",
  };
  changeTo: {
    open: boolean;
    item: null | subscription;
  } = {
    open: false,
    item: null,
  };
  get getSubscription() {
    return SubscriptionModule.getSubscription;
  }
  get getTrailRun() {
    return SubscriptionModule.getTrial;
  }

  get getPackages() {
    return SubscriptionModule.getPackages;
  }
  loading = false;
  // packages: subscription[] = [];
  promptPayfast: {
    open: boolean;
    item: subscription | null;
  } = {
    open: false,
    item: null,
  };
  get getLoggedIn() {
    return AuthModule.isLoggedIn;
  }
  async updatePackage(id: number) {
    const res = await Subscription.updateSubscription(id);
    console.log("Change package ", res);
  }
  async changePackage(id: number) {
    try {
      this.loading = true;
      await this.updatePackage(id);
      await Subscription.getSubscription();
      await this.loadPrices();
      this.changeTo.open = false;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }
  async redirectToPayfast() {
    try {
      if (this.promptPayfast.item == null) return;
      const temp = await this.signUp(this.promptPayfast.item.id);
      console.log("Redirect response");
    } catch (err) {
      console.log("Cant subscribe: ", err);
    }
  }
  async signUp(id: number) {
    try {
      this.loading = true;
      if (AuthModule.isLoggedIn) {
        console.log("Signup?", AuthModule.getUserRoles.length);
        if (AuthModule.getUserRoles.length > 0) {
          if (AuthModule.getUserRoles.some((e) => e.roleId == 2)) {
            // alert("Can signup");
            console.log("item: ", id);
            await Subscription.addSubscriber(id);
            // window.open(redirect);
          } else {
            alert("Your account doesnt have sufficient permissions");
          }
          // AuthModule.getLoggedInD
        }
      } else {
        alert("Push to login/register");
      }
    } catch (err) {
      console.log("Error signing up ", err);
    } finally {
      this.loading = false;
    }
  }
  async mounted() {
    try {
      await this.loadPrices();
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const temp = err as AxiosError;
        if (temp.response && temp.response.data) {
          if ((temp.response.data as any).status) {
            this.error.message = "";
            this.error.message =
              (temp.response.data as any).status +
              ": " +
              (temp.response.data as any).title;
            this.error.open = true;
            return;
          }

          this.error.message =
            temp.response.status + ": " + temp.response.statusText;
          this.error.open = true;
        } else {
          this.error.message = temp.code + ": " + temp.message;
          this.error.open = true;
        }
      }
    }
  }
  async loadPrices() {
    await Subscription.loadPrices();
  }
}
</script>
<style lang="scss">
.selectedPrice.v-card.v-sheet {
  // border: 3px solid green;
  // border-style: solid;
  box-shadow: var(--v-primary-lighten2) 0px 0px 0px 7px;
}
.selectedPricesTick {
  position: absolute;
  top: 50%;
  left: -25px;
  bottom: 50%;
}
</style>
