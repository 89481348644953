<template>
  <v-container class="gradientBg white--text py-10" fluid>
    <v-row dense justify="center">
      <v-col cols="10"
        ><div class="text-uppercase text-h1 mb-5 text-center white--text">
          Prices
        </div></v-col
      ><v-col cols="12"
        ><div class="text-uppercase text-body-1 mb-5">
          * Login is required to subscribe to packages
        </div></v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="text-h6 mb-3">Available Packages</div>
      </v-col>
    </v-row>
    <PriceList></PriceList>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import { axiosInstance } from "@/plugins/axios";
import { UserDetailsResponse, userRole } from "models";
import { subscription } from "subscriptionModels";
import AuthModule from "@/store/modules/Auth";
import SubscriptionModule from "@/store/modules/Subscription";
import PriceList from "@/components/PriceList.vue";
import { Subscription } from "@/helpers";
import axios, { AxiosError } from "axios";

@Component({
  components: {
    PriceList,
  },
})
export default class Prices extends Vue {}
</script>
<style lang="scss">
.selectedPrice.v-card.v-sheet {
  // border: 3px solid green;
  // border-style: solid;
  box-shadow: var(--v-primary-lighten2) 0px 0px 0px 7px;
}
.selectedPricesTick {
  position: absolute;
  top: 50%;
  left: -25px;
  bottom: 50%;
}
</style>
