import {
  axiosInstance,
  axiosNoErrorInstance,
  generateMessageFromError,
} from "@/plugins/axios";
import Auth from "@/store/modules/Auth";
import SubscriptionModule from "@/store/modules/Subscription";
import axios from "axios";
import { SubscriptionTrial } from "models";
import { subscription } from "subscriptionModels";
import Authenticate from "./Authenticate";

const Subscription = () => {
  const authHook = Authenticate();
  const addSubscriber = async (id: number) => {
    const res = await axiosInstance.get("/api/subscriptions/addSubscriber", {
      params: {
        subscriptionID: id,
      },
    });
    console.log("Res for subscribe: ", res);
    const redirect = (res.data.message as string).split("Added Subscriber,")[1];
    window.location.replace(redirect);
    return Promise.resolve();
  };
  const removeSubscriber = async () => {
    const res = await axiosInstance.get(
      "/api/subscriptions/cancelSubscription"
    );
    console.log("Res for unsubscribe: ", res);
    return Promise.resolve();
  };
  const getSubscription = async () => {
    try {
      const res = await axiosInstance.get(
        "/api/subscriptions/getUserSubscription",
        {
          headers: { skipError: true },
        }
      );

      if (res && res.status === 200) {
        SubscriptionModule.setSubscription(res.data as subscription);
      } else {
        SubscriptionModule.setSubscription(null);
      }

      console.log("Res for getSub: ", res);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response && err.response.status === 404) {
          console.warn("Trust 404");
        }

        // Attempt trial run and user refresh on error
        try {
          await getTrialRun();
          await authHook.getUser();
        } catch (innerErr) {
          console.error("Error in trial run or user refresh", innerErr);
          return Promise.reject(innerErr);
        }

        return Promise.resolve(null);
      }

      console.error("Unexpected error", err);
      return Promise.reject(err);
    }

    return Promise.resolve();
  };
  // const getSubscription = async () => {
  //   if (Auth.isLoggedIn) {
  //     const res = await axiosNoErrorInstance
  //       .get("/api/subscriptions/getUserSubscription")
  //       .catch((err) => {
  //         const msg = generateMessageFromError(err);
  //         if (axios.isAxiosError(err)) {
  //           if (err.response && err.response.status == 404) {
  //             return Promise.resolve(null);
  //           }
  //         }
  //         return Promise.reject(err);
  //       });
  //     if (res != null && res.status == 200) {
  //       SubscriptionModule.setSubscription(res.data as subscription);
  //     } else {
  //       SubscriptionModule.setSubscription(null);
  //       await getTrialRun();
  //     }
  //     console.log("Res for getSub: ", res);
  //     return Promise.resolve(res ? res.data : null);
  //   }
  // };
  const updateSubscription = async (
    id: number,
    isAdmin?: boolean,
    additionalUsers?: number
  ) => {
    const res = await axiosInstance.post(
      "/api/subscriptions/updateUserSubscription",
      {
        subscriptionID: id,
        additionalUsers: additionalUsers,
        isAdminUser: isAdmin,
      }
    );
    console.log("Res for getSub: ", res);
    return Promise.resolve(res.data);
  };

  const loadPrices = async () => {
    const res = await axiosInstance.get(
      "/api/PayFastSubscription/getAllSubscriptions"
    );
    console.log("Load prices ", res.data);
    SubscriptionModule.setPackages(res.data as subscription[]);
    return;
  };
  const startTrial = async (securityFirmId: number) => {
    const res = await axiosInstance.get<SubscriptionTrial>(
      "/api/Subscriptions/StartTrialRun",
      {
        params: {
          securityFirmId,
        },
      }
    );
    return;
  };
  const getTrialRun = async () => {
    const res = await axiosInstance.get<SubscriptionTrial>(
      "/api/Subscriptions/getUserTrialRun"
    );
    SubscriptionModule.setTrial(res.data);
    return res.data;
  };

  return {
    startTrial,
    loadPrices,
    updateSubscription,
    addSubscriber,
    removeSubscriber,
    getSubscription,
    getTrialRun,
  };
};
export default Subscription;
