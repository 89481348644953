<template>
  <v-container fluid class="gradientBg py-10 px-10">
    <v-row>
      <v-col cols="12"
        ><div class="text-h2 white--text mb-5">
          FIREARM COMPLIANCE MANAGEMENT SOFTWARE
        </div>
        <div class="text-h5 white--text">
          The purpose of the Firearm Control Act 60/2000 is as follows:
        </div>
        <div class="text-h6 white--text">
          "to establish a comprehensive and effective system of firearm control
          and management &amp; ensure the efficient monitoring and enforcement
          of legislation pertaining to the control of firearms"
        </div></v-col
      >
    </v-row>
    <v-row align="stretch">
      <v-col
        v-for="item in features"
        :key="item.text"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <!-- <v-card
                outlined
                dark
                style="border: 1px solid #fff"
                class="py-4"
                color="blue"
              > -->
        <v-container
          style="border: 1px solid #fff; height: 100%"
          fluid
          class="pa-3"
        >
          <v-row justify="center" align="center" class="pt-5">
            <v-col cols="auto" class="bg-icon-testimonials"
              ><v-icon color="white">{{ item.icon }}</v-icon></v-col
            >
          </v-row>
          <v-row justify="center" align="center" class="pb-5">
            <v-col cols="auto"
              ><div class="text-center white--text">
                {{ item.text }}
              </div></v-col
            >
          </v-row>
        </v-container>
        <!-- </v-card> -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class Features extends Vue {
  features = [
    {
      icon: "mdi-menu",
      text: "Uncluttered & User-friendly Navigation",
    },
    {
      icon: "mdi-account-tie-voice",
      text: "Expert Instruction",
    },
    {
      icon: "mdi-briefcase-variant",
      text: "Regulation 21 Expiry Monitoring",
    },
    {
      icon: "mdi-card-account-details",
      text: "Drivers Licence Expiry Monitoring",
    },
    {
      icon: "mdi-office-building",
      text: "Business Purpose Proficiency",
    },
    {
      icon: "mdi-account",
      text: "SAPS Competency Expiry Monitoring",
    },
    {
      icon: "mdi-laptop",
      text: "SAPS Competency Validity Reports",
    },
    {
      icon: "mdi-cellphone",
      text: "Mobile App",
    },
    {
      icon: "mdi-card-account-details",
      text: "Drivers Licence Validity Reports",
    },
    {
      icon: "mdi-laptop-account",
      text: "PSIRA Validity Reports",
    },
    {
      icon: "mdi-shield-account",
      text: "Proficiency Certificate Reports",
    },
    {
      icon: "mdi-shield-half-full",
      text: "Regulation 21 Validity Reports",
    },
  ];
}
</script>

<style lang="scss">
.gradientBg {
  background-image: linear-gradient(to right, #1660a8, #4f7ef6);
}
</style>
