<template>
  <v-container fluid class="client-review-container">
    <v-row justify="start">
      <v-col cols="10">
        <div class="text-center text-h1">CLIENTS LOVE ZEROEIGHT.CO.ZA</div>
      </v-col>
    </v-row>
    <v-row justify="center" align="stretch">
      <v-col
        cols="12"
        sm="5"
        md="4"
        v-for="item in testimonials"
        :key="item.title"
      >
        <v-card
          color="rgba(234,239,250,0.6)"
          tile
          class="text-center py-5"
          width="100%"
          height="100%"
          elevation="2"
        >
          <v-avatar size="60" class="text-center"
            ><v-img contain :src="item.img"></v-img
          ></v-avatar>
          <v-card-title
            ><div class="mx-auto text-subtitle-1">
              {{ item.title }}
            </div></v-card-title
          >
          <v-card-subtitle class="text-subtitle-2">{{
            item.subtitle
          }}</v-card-subtitle>
          <v-card-text>
            <div class="text-body-1">"{{ item.message }}"</div>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <div class="cont">
          <input type="radio" name="slider" id="item-1" checked />
          <input type="radio" name="slider" id="item-2" />
          <input type="radio" name="slider" id="item-3" />
          <div class="cards">
            <label @click="testLabel" class="card" for="item-1" id="song-1">
              <v-img
                height="200px"
                width="200px"
                class="rounded-circle"
                contain
                src="@/assets/testimonials/cpg.jpg"
                alt="song"
              />
            </label>
            <label class="card" for="item-2" id="song-2">
              <v-img
                height="200px"
                width="200px"
                class="rounded-circle"
                contain
                src="@/assets/testimonials/cpg.jpg"
                alt="song"
              />
            </label>
            <label class="card" for="item-3" id="song-3">
              <v-img
                height="200px"
                width="200px"
                class="rounded-circle"
                contain
                src="@/assets/testimonials/cpg.jpg"
                alt="song"
              />
            </label>
          </div>
        </div> -->
      <!-- <v-carousel
                :continuous="false"
                :cycle="true"
                :show-arrows="true"
                height="300"
                hide-delimiter-background
                delimiter-icon="mdi-circle"
              >
                <v-carousel-item
                  v-for="(item, i) in testimonials"
                  :key="item.title"
                >
                  <v-sheet color="transparent" height="100%" tile>
                    <v-row align="center" justify="center">
                      <v-col cols="10">
                        <v-avatar
                          ><v-img
                            v-if="i - 1 >= 0"
                            contain
                            height="50"
                            :src="testimonials[i - 1].img"
                            @error="testimonialsError"
                          ></v-img
                        ></v-avatar>
                        <v-avatar
                          ><v-img
                            contain
                            height="50"
                            :src="item.img"
                            @error="testimonialsError"
                          ></v-img
                        ></v-avatar>
                        <v-img
                          v-if="i + 1 < testimonials.length"
                          contain
                          height="50"
                          :src="testimonials[i + 1].img"
                          @error="testimonialsError"
                        ></v-img>
                      </v-col>
                      <v-col cols="10"
                        ><div class="text-h3 text-center">
                          {{ item.title }}
                        </div></v-col
                      >
                      <v-col cols="10"
                        ><div class="text-h4 text-center">
                          {{ item.subtitle }}
                        </div></v-col
                      >
                      <v-col cols="10"
                        ><div class="text-h6 text-center">
                          {{ item.message }}
                        </div></v-col
                      >
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel> -->
      <!-- </v-col> -->
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
export default class Testimonials extends Vue {
  testimonialsError(err: any) {
    console.error("Testimonials failed: ", err);
  }
  testLabel() {
    console.log("Testing label click");
  }
  testimonials = [
    {
      title: "Sweiss Harms",
      subtitle: "CPG SECURITY - OWNER",
      img: require("@/assets/testimonials/cpg.jpg"),
      message:
        "I am operationally active in my business. Zero Eight gives me the compliance information I need to make decisions on the go",
    },
    {
      title: "Roger Ladeira",
      subtitle: "REAPER SECURITY – OWNER",
      img: require("@/assets/testimonials/reaper.png"),
      message:
        "When I heard it was software, I thought it was going to cost an arm and a leg. Zero Eight is surprisingly affordable for what it does and Carl goes out of his way to service his clients",
    },
    {
      title: "Milton",
      subtitle: "STALLION SECURITY – CONTROL ROOM MANAGER",
      img: require("@/assets/testimonials/Stallion.jpg"),
      message:
        "This system is easy to use and I have all the information I need online. No more excel spreadsheets. The online permits have saved us a lot in travelling time and expense",
    },
    {
      title: "Jonathan Maier",
      subtitle: "PHP SECURITY – OPERATIONS MANAGER",
      img: require("@/assets/testimonials/PHPSecurity.png"),
      message:
        "Zero Eight gives me access to compliance information wherever I am. I no longer need to wait for the information I need’’ The online firearm permits have saved us a lot of time, especially when it comes to auditing",
    },
    {
      title: "Ashley Scheun",
      subtitle: "MAMBA SECURITY – OPERATIONS MANAGER",
      img: require("@/assets/testimonials/Mamba.png"),
      message:
        "Great service from Carl and his team. The software is awesome! It has made my life less complicated when it comes to compliance And is very user-friendly",
    },
  ];
}
</script>

<style lang="scss">
.client-review-container {
  background-image: url("@/assets/clients_love_us.png");
  background-repeat: no-repeat; /* Do not repeat the image */
  background-position: center right; /* Center the image */
  background-size: 500px;
  min-height: 600px;
}
.cards {
  position: relative;
  width: 400px;
  height: 100%;
  margin-bottom: 20px;
}
.card {
  position: absolute;
  width: 60%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  transition: transform 0.4s ease;
  cursor: pointer;
}
img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}
#item-1:checked ~ .cards #song-3,
#item-2:checked ~ .cards #song-1,
#item-3:checked ~ .cards #song-2 {
  transform: translatex(-90%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

#item-1:checked ~ .cards #song-2,
#item-2:checked ~ .cards #song-3,
#item-3:checked ~ .cards #song-1 {
  transform: translatex(90%) scale(0.8);
  opacity: 0.4;
  z-index: 0;
}

#item-1:checked ~ .cards #song-1,
#item-2:checked ~ .cards #song-2,
#item-3:checked ~ .cards #song-3 {
  transform: translatex(0) scale(1);
  opacity: 1;
  z-index: 1;

  img {
    box-shadow: 0px 0px 5px 0px rgba(81, 81, 81, 0.47);
  }
}
input[type="radio"] {
  display: none;
}
#item-2:checked ~ .player #test {
  transform: translateY(0);
}

#item-2:checked ~ .player #test {
  transform: translateY(-40px);
}

#item-3:checked ~ .player #test {
  transform: translateY(-80px);
}
.bg-icon-testimonials {
  background-image: url("@/assets/testimonials/bg_icon.png");
  background-size: cover;
}
.cont {
  width: 100%;
  max-width: 800px;
  max-height: 600px;
  height: 300px;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
