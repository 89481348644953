<template>
  <v-navigation-drawer temporary height="100%" right dark v-model="sidebar" app>
    <div class="d-flex justify-end">
      <v-btn @click="sidebar = false" class="text-end pa-3 ma-2" icon
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </div>
    <v-list class="mt-0">
      <v-list-item v-if="getAuth">
        <v-list-item-icon><v-icon>mdi-account</v-icon> </v-list-item-icon>
        <v-list-item-content>
          <div class="text-body-1 text-break">{{ getAuth }}</div>
        </v-list-item-content>
      </v-list-item>
      <v-divider v-if="getAuth"></v-divider>
      <v-list-item
        v-for="item in menuItems"
        :key="item.title"
        :to="{
          hash: item.path.includes('#') == false ? undefined : item.path,
          name: item.path.includes('#') == true ? 'home' : item.path,
        }"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>{{ item.title }}</v-list-item-content>
      </v-list-item>
      <v-list-item
        text
        height="30"
        active-class="rounded"
        rounded
        class="text-none py-1 mx-1"
        href="https://app.zeroeight.co.za/"
        target="blank"
      >
        <v-list-item-icon>
          <v-icon>mdi-application</v-icon>
        </v-list-item-icon>
        <v-list-item-content>Zero8 App</v-list-item-content>
      </v-list-item>
    </v-list>
    <span class="d-flex justify-center hidden-md-and-up">
      <v-btn
        width="80"
        v-if="getAuth == null"
        outlined
        rounded
        class="ma-2"
        :to="{
          name: 'login',
        }"
        >Login</v-btn
      ><v-btn width="80" v-else outlined rounded class="ma-2" @click="logout"
        >Logout</v-btn
      ><v-btn
        width="80"
        :to="{
          name: 'home',
          hash: '#faq',
        }"
        outlined
        rounded
        class="ma-2"
        >FAQ</v-btn
      >
    </span>
  </v-navigation-drawer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import Auth from "@/store/modules/Auth";

@Component
export default class Navigation extends Vue {
  get getAuth() {
    if (Auth.getLoggedIn) {
      return Auth.getLoggedIn.username;
    }
    return null;
  }
  get sidebar() {
    return Nav.getSidebar;
  }
  set sidebar(val: boolean) {
    Nav.setSideBar(val);
  }
  get menuItems() {
    return Nav.getMenuItems;
  }
  logout() {
    console.log("Test log out");
    Auth.removeLoggedIn();
  }
}
</script>
