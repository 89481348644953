<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" sm="11">
        <div></div>
      </v-col>
    </v-row>
    <v-row v-if="getSubscription" justify="center">
      <v-col cols="12" sm="10">
        <v-card class="d-flex flex-column">
          <v-card-title class="gradientBg justify-start text-uppercase">
            <div class="text-h3 white--text text-break">
              Current Subscription: {{ getSubscription.subscriptionName }}
            </div>
          </v-card-title>
          <v-card-text class="py-2 px-0">
            <v-container fluid class="pa-0">
              <v-expansion-panels v-model="open" accordion flat class="mb-3">
                <v-expansion-panel>
                  <v-expansion-panel-header class="py-1"
                    ><div class="text-h4">
                      Package Information
                    </div></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-container fluid class="pa-0">
                      <v-row align="start">
                        <v-col cols="6" class="text-left">
                          <div class="text-h6 text-decoration-underline my-3">
                            User counts
                          </div>
                          <div
                            class="text-body-1 mb-2"
                            :class="{
                              'error--text':
                                getSubscription.userCount >=
                                getSubscription.numberOfUsersAllowed +
                                  getSubscription.additonalUsers,
                            }"
                          >
                            Users: {{ getSubscription.userCount }} /
                            {{ getSubscription.numberOfUsersAllowed }}
                            <span
                              class="success--text"
                              v-if="getSubscription.additonalUsers != 0"
                              >+ {{ getSubscription.additonalUsers }}</span
                            >
                            <v-btn
                              @click="openAdjustAdditionalUsers()"
                              class="ml-4 mt-n1"
                              icon
                              small
                              ><v-icon>mdi-information-outline</v-icon></v-btn
                            >
                          </div>

                          <div
                            class="text-body-1 mb-2"
                            :class="{
                              'error--text':
                                getSubscription.aroCount >=
                                getSubscription.numberOfArmedResponseOfficersAllowed,
                            }"
                          >
                            ARO: {{ getSubscription.aroCount }} /
                            {{
                              getSubscription.numberOfArmedResponseOfficersAllowed
                            }}
                            <v-btn
                              v-if="
                                getSubscription.aroCount >=
                                getSubscription.numberOfArmedResponseOfficersAllowed
                              "
                              @click="openAdjustAdditionalARO()"
                              class="ml-4 mt-n1"
                              :color="
                                getSubscription.aroCount >=
                                getSubscription.numberOfArmedResponseOfficersAllowed
                                  ? 'error'
                                  : ''
                              "
                              icon
                              small
                              ><v-icon>mdi-information-outline</v-icon></v-btn
                            >
                          </div>
                          <div
                            class="text-body-1 mb-2"
                            :class="{
                              'error--text':
                                getSubscription.soCount >=
                                getSubscription.numberOfSecurityOfficersAllowed +
                                  getSubscription.additionalSOUsers,
                            }"
                          >
                            SO: {{ getSubscription.soCount }} /
                            {{
                              getSubscription.numberOfSecurityOfficersAllowed
                            }}
                            <span
                              class="success--text"
                              v-if="getSubscription.additionalSOUsers != 0"
                              >+ {{ getSubscription.additionalSOUsers }}</span
                            >
                            <v-btn
                              @click="openAdjustAdditionalSO()"
                              class="ml-4 mt-n1"
                              :color="
                                getSubscription.soCount >=
                                getSubscription.numberOfSecurityOfficersAllowed
                                  ? 'error'
                                  : ''
                              "
                              icon
                              small
                              ><v-icon>mdi-information-outline</v-icon></v-btn
                            >
                          </div>
                          <template
                            v-if="
                              getSubscription.issuePermits == true ||
                              getSubscription.mobileApp ||
                              getSubscription.reporting
                            "
                          >
                            <div class="text-h6 text-decoration-underline my-3">
                              Access...
                            </div>
                            <div
                              class="text-body-1 mb-2"
                              v-if="getSubscription.mobileApp == true"
                            >
                              Mobile Permit Application
                            </div>
                            <div
                              class="text-body-1 mb-2"
                              v-if="getSubscription.reporting == true"
                            >
                              Reporting
                            </div>
                          </template>

                          <div
                            class="text-body-1 mb-2"
                            v-if="getSubscription.issuePermits == true"
                          >
                            Issue Permits
                          </div></v-col
                        >
                        <v-col cols="6" class="text-left"
                          ><template
                            v-if="
                              Object.entries(getSubscription).find(
                                ([key, value]) =>
                                  key.toLowerCase().includes('manage') &&
                                  value == true
                              ) != null
                            "
                          >
                            <div class="text-h6 text-decoration-underline my-3">
                              Permissions to manage
                            </div>
                            <div
                              class="text-body-1 mb-2"
                              v-if="getSubscription.manageAmmunition == true"
                            >
                              Ammunition
                            </div>
                            <div
                              class="text-body-1 mb-2"
                              v-if="
                                getSubscription.manageArmedResponseOfficers ==
                                true
                              "
                            >
                              Armed Response officers
                            </div>
                            <div
                              class="text-body-1 mb-2"
                              v-if="getSubscription.manageFirearms == true"
                            >
                              Firearms
                            </div>
                            <div
                              class="text-body-1 mb-2"
                              v-if="getSubscription.manageIncidents == true"
                            >
                              Incidents
                            </div>
                            <div
                              class="text-body-1 mb-2"
                              v-if="
                                getSubscription.manageSecurityOfficers == true
                              "
                            >
                              Security Officers
                            </div>
                            <div
                              class="text-body-1 mb-2"
                              v-if="getSubscription.manageTeams == true"
                            >
                              Teams
                            </div>
                          </template></v-col
                        >
                      </v-row>
                    </v-container>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-row justify="space-between" class="px-5">
                <v-col cols="auto">
                  <v-btn
                    outlined
                    color="secondary"
                    @click="cancelSubscriptionToggle = true"
                    >Cancel Subscription</v-btn
                  >
                  <v-dialog v-model="cancelSubscriptionToggle" width="600px">
                    <v-card>
                      <v-card-title class="error white--text">
                        Are you sure you want to cancel your subscription?
                      </v-card-title>
                      <v-card-text class="py-4">
                        <div class="text-body-1">
                          This might result in a purge of data after X amount of
                          days. You will need to signup through payfast to
                          receive a subscription again.
                        </div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-end">
                        <v-btn
                          outlined
                          color="secondary"
                          @click="cancelSubscriptionToggle = false"
                          >Close</v-btn
                        >
                        <v-btn color="secondary" @click="cancelSubscription"
                          >Confirm</v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-col>
                <v-col cols="auto"
                  ><v-btn
                    color="secondary"
                    @click="
                      changeSubscriptionToggle = !changeSubscriptionToggle
                    "
                    >Change Subscription</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end"> </v-card-actions> </v-card
        ><v-slide-y-transition
          ><v-card class="mt-4" v-if="changeSubscriptionToggle">
            <v-card-actions class="justify-end">
              <v-btn
                icon
                color="black"
                @click="changeSubscriptionToggle = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
            <v-card-text class="pa-0">
              <PriceList></PriceList>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
    <v-row v-else-if="getTrialRun && AuthModule.getUser" justify="center">
      <v-col cols="12" sm="10">
        <v-card class="d-flex flex-column">
          <v-card-title class="gradientBg justify-start text-uppercase">
            <div class="text-h3 white--text text-break">
              {{
                getTrialRun?.status == subType.PAYFASTPASS
                  ? "Personalized Subscription"
                  : "Free Trial"
              }}
            </div>
          </v-card-title>
          <v-card-text class="py-2 px-0">
            <v-container fluid class="pa-0">
              <v-row justify="space-between" class="px-5" dense>
                <v-col cols="auto" class="text-body-2"
                  ><template v-if="getTrialRun?.status == subType.PAYFASTPASS"
                    >* To make any changes to your subscription please contact
                    accounts directly
                  </template>
                  <template v-else
                    >* Upgrade to a paid version to unlock user management
                  </template>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-expansion-panels
                    v-model="open"
                    accordion
                    flat
                    class="mb-3"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-1"
                        ><div class="text-h4">
                          Package Information
                        </div></v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <v-container fluid class="pa-0">
                          <v-row align="start">
                            <v-col cols="6" class="text-left">
                              <div
                                v-if="
                                  getTrialRun?.status != subType.PAYFASTPASS
                                "
                                class="text-h6 my-3"
                              >
                                Trial status :
                                {{
                                  getTrialRun?.status == subType.ACTIVE
                                    ? "Active"
                                    : "Expired"
                                }}
                              </div>
                              <div
                                class="text-h6 text-decoration-underline my-3"
                              >
                                User counts
                              </div>
                              <div class="text-body-1 mb-2">
                                Users: {{ getTrialRun.userCount }} /
                                {{ AuthModule.getUser.securityFirm.userLimit }}
                              </div>
                              <div class="text-body-1 mb-2">
                                AROs: {{ getTrialRun.aroCount }} /
                                {{ AuthModule.getUser.securityFirm.aroLimit }}
                              </div>
                              <div class="text-body-1 mb-2">
                                Security Guard Officers:
                                {{ getTrialRun.soCount }} /
                                {{ AuthModule.getUser.securityFirm.sgLimit }}
                              </div>
                              <template>
                                <div
                                  class="text-h6 text-decoration-underline my-3"
                                >
                                  Access...
                                </div>
                                <div class="text-body-1 mb-2">
                                  Mobile Permit Application
                                </div>
                                <div class="text-body-1 mb-2">Reporting</div>
                              </template>

                              <div class="text-body-1 mb-2">Issue Permits</div>
                            </v-col>
                            <v-col cols="6" class="text-left">
                              <div
                                class="text-h6 text-decoration-underline my-3"
                              >
                                Permissions to manage
                              </div>
                              <div class="text-body-1 mb-2">Ammunition</div>
                              <div class="text-body-1 mb-2">
                                Armed Response officers
                              </div>
                              <div class="text-body-1 mb-2">Firearms</div>
                              <div class="text-body-1 mb-2">Incidents</div>
                              <div class="text-body-1 mb-2">
                                Security Officers
                              </div>
                              <div class="text-body-1 mb-2">Teams</div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              color="secondary"
              @click="changeSubscriptionToggle = !changeSubscriptionToggle"
              >{{
                getTrialRun?.status == subType.PAYFASTPASS
                  ? "View other Subscriptions"
                  : "Upgrade to a subscription"
              }}</v-btn
            ></v-card-actions
          > </v-card
        ><v-slide-y-transition
          ><v-card class="mt-4" v-if="changeSubscriptionToggle">
            <v-card-actions class="justify-end">
              <v-btn
                icon
                color="black"
                @click="changeSubscriptionToggle = false"
                ><v-icon>mdi-close</v-icon></v-btn
              >
            </v-card-actions>
            <v-card-text class="pa-0">
              <PriceList></PriceList>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
      </v-col>
    </v-row>
    <v-row justify="center" v-else>
      <v-col cols="auto"
        ><div class="text-h3 mt-5">
          You currently have no subscriptions.
        </div></v-col
      >
    </v-row>
    <v-dialog v-model="adjustAdditionalUsers.open" width="500px">
      <v-card v-if="getSubscription">
        <v-card-title class="primary white--text text-h6">
          Users: {{ getSubscription.userCount }} /
          {{
            getSubscription
              ? getSubscription.numberOfUsersAllowed +
                getSubscription.additonalUsers
              : "NA"
          }}
        </v-card-title>
        <v-card-text class="justify-center black--text">
          <v-container>
            <div v-if="getSubscription" class="text-body-1">
              <div>
                Would you like to adjust the number of additional users of your
                subscription?
              </div>
              Current monthly amount:
              {{ currentSubscriptionValues.totalCost }} <br />
              <template
                v-if="
                  currentSubscriptionValues.totalCost !=
                  adjustedSubscriptionValues.totalAdjustedCost
                "
              >
                New monthly amount:
                {{ adjustedSubscriptionValues.totalAdjustedCost }}</template
              >
            </div>
            <v-row justify="center">
              <v-col cols="10"
                ><v-text-field
                  class="mt-8 addUserField"
                  outlined
                  block
                  type="number"
                  v-model="adjustAdditionalUsers.amount"
                  label="Users"
                  persistent-hint
                  :hint="`Currently paying for: ${getSubscription.additonalUsers}`"
                >
                  <template #prepend>
                    <v-btn
                      icon
                      :disabled="
                        adjustAdditionalUsers.amount <=
                        getSubscription.userCount -
                          (getSubscription
                            ? getSubscription.numberOfUsersAllowed
                            : 0)
                      "
                      @click="adjustAdditionalUsers.amount -= 1"
                      ><v-icon>mdi-minus</v-icon></v-btn
                    >
                  </template>
                  <template #append-outer>
                    <v-btn icon @click="adjustAdditionalUsers.amount += 1"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                </v-text-field></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            color="primary"
            @click="adjustAdditionalUsers.open = false"
          >
            Close
          </v-btn>
          <v-btn
            color="primary"
            :disabled="
              adjustAdditionalUsers.amount == getSubscription.additonalUsers
            "
            :loading="loading"
            @click="updateSubscription(true)"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="adjustAdditionalSO.open" width="500px">
      <v-card v-if="getSubscription">
        <v-card-title class="primary white--text text-h6">
          Security Officers: {{ getSubscription.soCount }} /
          {{
            getSubscription
              ? getSubscription.numberOfSecurityOfficersAllowed +
                getSubscription.additionalSOUsers
              : "NA"
          }}
        </v-card-title>
        <v-card-text class="justify-center black--text">
          <v-container>
            <div v-if="getSubscription" class="text-body-1">
              <p>
                Would you like to adjust the number of additional security
                officers of your subscription?
              </p>
              Current monthly amount:
              {{ currentSubscriptionValues.totalCost }} <br />
              <template
                v-if="
                  currentSubscriptionValues.totalCost !=
                  adjustedSubscriptionValues.totalAdjustedCost
                "
              >
                New monthly amount:
                {{ adjustedSubscriptionValues.totalAdjustedCost }}</template
              >
            </div>
            <v-row justify="center">
              <v-col cols="10"
                ><v-text-field
                  class="mt-8 addUserField"
                  outlined
                  block
                  type="number"
                  v-model="adjustAdditionalSO.amount"
                  label="Users"
                  persistent-hint
                  :hint="`Currently paying for: ${getSubscription.additionalSOUsers}`"
                >
                  <template #prepend>
                    <v-btn
                      icon
                      :disabled="
                        adjustAdditionalSO.amount <= 0 ||
                        adjustAdditionalSO.amount <=
                          getSubscription.soCount -
                            (getSubscription
                              ? getSubscription.numberOfSecurityOfficersAllowed +
                                getSubscription.additionalSOUsers
                              : 0)
                      "
                      @click="adjustAdditionalSO.amount -= 1"
                      ><v-icon>mdi-minus</v-icon></v-btn
                    >
                  </template>
                  <template #append-outer>
                    <v-btn icon @click="adjustAdditionalSO.amount += 1"
                      ><v-icon>mdi-plus</v-icon></v-btn
                    >
                  </template>
                </v-text-field></v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="primary" @click="adjustAdditionalSO.open = false">
            Close
          </v-btn>
          <v-btn
            color="primary"
            :disabled="
              adjustAdditionalSO.amount == getSubscription.additionalSOUsers
            "
            :loading="loading"
            @click="updateSubscription(false)"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="additionalAROS.open" width="500px">
      <v-card v-if="getSubscription">
        <v-card-title class="primary white--text text-h6">
          Armed Response Officers: {{ getSubscription.aroCount }} /
          {{
            getSubscription
              ? getSubscription.numberOfArmedResponseOfficersAllowed
              : "NA"
          }}
        </v-card-title>
        <v-card-text class="justify-center black--text pa-1">
          <v-container>
            <div v-if="getSubscription" class="text-body-1">
              <p>
                You've reached the limit for Armed Response Officers (AROs)
                under your current subscription. To add more AROs, please
                upgrade to a subscription plan that meets your needs.
              </p>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text color="primary" @click="additionalAROS.open = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import { Subscription } from "@/helpers";
import SubscriptionModule from "@/store/modules/Subscription";
import PriceList from "@/components/PriceList.vue";
import { SubscriptionTrialStatus } from "@/enum/trialRun";
import Auth from "@/store/modules/Auth";

@Component({
  components: {
    PriceList,
  },
})
export default class ManageSubscription extends Vue {
  subType = SubscriptionTrialStatus;
  AuthModule = Auth;
  open = 0;
  loading = true;
  changeSubscriptionToggle = false;
  cancelSubscriptionToggle = false;
  additionalAROS = {
    open: false,
  };
  additionalUsers = 0;
  adjustAdditionalUsers = {
    open: false,
    amount: this.getSubscription?.additonalUsers || 0,
  };
  adjustAdditionalSO = {
    open: false,
    amount: this.getSubscription?.additionalSOUsers || 0,
  };
  get getTrialRun() {
    return SubscriptionModule.getTrial;
  }
  get currentSubscriptionValues() {
    if (this.getSubscription == null) {
      return { baseUserCost: 0, baseSOCost: 0, totalCost: 0, error: null };
    }

    // Base subscription details
    const allowedUsers = this.getSubscription.numberOfUsersAllowed;
    const allowedSO = this.getSubscription.numberOfSecurityOfficersAllowed;
    const currentUsers = this.getSubscription.userCount;
    const currentSO = this.getSubscription.soCount;

    // Calculate additional users and SO
    const additionalUsers =
      currentUsers > allowedUsers ? currentUsers - allowedUsers : 0;
    const additionalSO = currentSO > allowedSO ? currentSO - allowedSO : 0;

    // **NEW**: Add `paidAdditionalUsers` to track what they're currently paying for
    const paidAdditionalUsers = this.getSubscription.additonalUsers ?? 0; // How many additional users they paid for
    const paidAdditionalSO = this.getSubscription.additionalSOUsers ?? 0; // How many additional SOs they paid for

    // Base cost for allowed users only (SO doesn't affect the base cost)
    const baseUserCost = this.getSubscription.defaultCostPerUser * allowedUsers;

    // Calculate additional users and SO costs
    const addUserCost =
      this.getSubscription.additionalUsersAmount * paidAdditionalUsers;
    const addSOCost =
      this.getSubscription.additionalSOUsersAmount * paidAdditionalSO;

    // Total current cost (base + additional costs)
    const totalCost = baseUserCost + addUserCost + addSOCost;

    return {
      baseUserCost: baseUserCost, // Pure base cost for allowed users
      addUserCost, // Additional users cost
      baseSOCost: addSOCost, // Only additional SO cost
      totalCost: totalCost, // Overall total (base + additional costs)
      additionalUsers, // Additional users currently in use
      additionalSO, // Additional SOs currently in use
      paidAdditionalUsers, // Paid additional users
      paidAdditionalSO, // Paid additional SOs
      allowedUsers, // Allowed users count
      allowedSO, // Allowed SO count
      error: null, // No error in this case
    };
  }
  @Watch("getSubscription", { immediate: true, deep: true })
  onSubscriptionUpdated(val) {
    if (!val) {
      return;
    }
    this.adjustAdditionalSO.amount = val.additionalSOUsers || 0;
    this.adjustAdditionalUsers.amount = val.additonalUsers || 0;
    console.log(
      "Trust ",
      this.adjustAdditionalSO.amount,
      this.adjustAdditionalUsers.amount,
      val
    );
  }
  @Watch("adjustAdditionalUsers.open", { immediate: true, deep: true })
  @Watch("adjustAdditionalSO.open", { immediate: true, deep: true })
  onUserOpenChanged(val: boolean, oldVal: boolean) {
    if (val == true) {
      return;
    }
    this.adjustAdditionalSO.amount =
      this.getSubscription?.additionalSOUsers || 0;
    this.adjustAdditionalUsers.amount =
      this.getSubscription?.additonalUsers || 0;
    console.log(
      "Trust ",
      this.adjustAdditionalSO.amount,
      this.adjustAdditionalUsers.amount,
      val
    );
  }
  get adjustedSubscriptionValues() {
    const baseValues = this.currentSubscriptionValues;

    // If there is an error or the subscription doesn't exist, return early
    if (baseValues.error) {
      return { error: baseValues.error };
    }

    // Safely access adjustAdditionalUsers and adjustAdditionalSO using optional chaining and provide defaults
    const adjustedAdditionalUsers = this.adjustAdditionalUsers?.amount ?? 0;
    const adjustedAdditionalSO = this.adjustAdditionalSO?.amount ?? 0;

    // **NEW**: Check the current number of paid slots for users/SOs
    const paidAdditionalUsers = baseValues.paidAdditionalUsers ?? 0;
    const paidAdditionalSO = baseValues.paidAdditionalSO ?? 0;

    // Use nullish coalescing to ensure these values are defined (fallback to 0 if undefined)
    const currentAdditionalUsers = baseValues.additionalUsers ?? 0;
    const currentAdditionalSO = baseValues.additionalSO ?? 0;

    // Ensure adjustments don't go negative compared to currently active additional users/SOs
    if (
      adjustedAdditionalUsers < currentAdditionalUsers ||
      adjustedAdditionalSO < currentAdditionalSO
    ) {
      return {
        error: "Cannot remove more users/SO than currently active.",
      };
    }

    // Safely access getSubscription properties with optional chaining and fallback to 0 if undefined
    const additionalUsersAmount =
      this.getSubscription?.additionalUsersAmount ?? 0;
    const additionalSOUsersAmount =
      this.getSubscription?.additionalSOUsersAmount ?? 0;

    // Adjust the costs based on the new number of additional slots (adjusted amount)
    const adjustedUserCost = additionalUsersAmount * adjustedAdditionalUsers;
    const adjustedSOCost = additionalSOUsersAmount * adjustedAdditionalSO;

    // Calculate the total adjusted cost
    const totalAdjustedCost =
      (baseValues.baseUserCost ?? 0) + adjustedUserCost + adjustedSOCost;

    return {
      adjustedUserCost: baseValues.baseUserCost + adjustedUserCost, // Adjusted total user cost
      adjustedSOCost: adjustedSOCost, // Adjusted SO cost based on input
      totalAdjustedCost: totalAdjustedCost, // Overall adjusted total (base + additional)
      error: null, // No error in this case
    };
  }

  openAdjustAdditionalUsers() {
    if (this.getSubscription == null) {
      return;
    }
    this.adjustAdditionalUsers.amount =
      this.getSubscription?.additonalUsers || 0;
    this.adjustAdditionalUsers.open = true;
  }
  openAdjustAdditionalSO() {
    if (this.getSubscription == null) {
      return;
    }
    this.adjustAdditionalSO.amount =
      this.getSubscription?.additionalSOUsers || 0;
    this.adjustAdditionalSO.open = true;
  }
  openAdjustAdditionalARO() {
    if (this.getSubscription == null) {
      return;
    }
    this.additionalAROS.open = true;
  }

  get getSubscription() {
    return SubscriptionModule.getSubscription;
  }
  async mounted() {
    try {
      this.loading = true;
      await Subscription.getSubscription();
    } catch (err) {
      console.log("Cannot init: ", err);
    } finally {
      this.loading = false;
    }
  }
  async cancelSubscription() {
    try {
      this.loading = true;
      await Subscription.removeSubscriber();
      await Subscription.getSubscription();
    } catch (err) {
      console.log("Error cancelling subscription: ", err);
    } finally {
      this.loading = false;
    }
  }
  async updateSubscription(isAdmin: boolean) {
    try {
      this.loading = true;
      if (SubscriptionModule.getSubscription == null) throw "No Subscription";
      await Subscription.updateSubscription(
        SubscriptionModule.getSubscription.id,
        isAdmin,
        isAdmin
          ? this.adjustAdditionalUsers.amount
          : this.adjustAdditionalSO.amount
      );
      await Subscription.getSubscription();
    } catch (err) {
      console.log("Error cancelling subscription: ", err);
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style lang="scss">
.addUsers {
  .v-input__append-inner {
    margin-top: 0;
  }
}
</style>
