<template>
  <v-snackbar
    app
    class="pa-1"
    :width="430"
    :min-width="200"
    max-width="90%"
    :id="currentNotif.id"
    :color="currentNotif.color"
    v-if="currentNotif != null"
    :timeout="currentNotif.timeout"
    :value="displayAnyNotifs"
    :multi-line="true"
    bottom
    right
  >
    <!-- :width="$vuetify.breakpoint.sm == true ? '80vw' : $vuetify.breakpoint.md == true ? '70vw' : $vuetify.breakpoint.lgAndUp == true ? '60vw' : '100vw'" right -->
    <v-container fluid class="pa-0">
      <v-btn
        :style="{
          top: $vuetify.breakpoint.smAndUp ? '25%' : '5px',
        }"
        style="position: absolute; right: 10px"
        color="white"
        icon
        @click="currentNotif ? closeNotif(currentNotif) : () => {}"
        ><v-icon>mdi-close</v-icon></v-btn
      >
      <v-row
        :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
        align="center"
      >
        <v-col cols="auto" order="1"
          ><v-icon :size="$vuetify.breakpoint.xs ? '45' : '35'">{{
            currentNotif.icon
          }}</v-icon></v-col
        >
        <!-- <v-col cols="auto" order="2">
          
        </v-col> -->
        <v-col
          cols="12"
          sm="10"
          class="text-break"
          order="3"
          :class="$vuetify.breakpoint.xs ? 'text-center' : 'text-start'"
        >
          <div
            class="text-body-1"
            v-if="currentNotif.title"
            v-html="currentNotif.title"
          ></div>
          <div class="text-body-2" v-html="currentNotif.message"></div>
        </v-col>
      </v-row>
    </v-container>

    <!-- <template v-slot:action="{ attrs }">
      <v-btn
        color="white"
        icon
        v-bind="attrs"
        @click="currentNotif ? closeNotif(currentNotif) : () => {}"
        ><v-icon>mdi-close</v-icon></v-btn
      >
    </template> -->
  </v-snackbar>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import NotificationSystem from "@/store/modules/NotificationSystem";
import { notificationType } from "models";

@Component
export default class GlobalNotifications extends Vue {
  get displayAnyNotifs(): boolean {
    return this.open;
  }
  currentNotif: null | notificationType = null;
  open = false;
  get getCurrentNotification(): notificationType | null {
    return NotificationSystem.getCurrentNotif;
  }
  closeNotif(notification: notificationType): void {
    this.open = false;
    setTimeout(
      NotificationSystem.clearCurrentNotifWithCheck,
      300,
      notification
    );
  }
  @Watch("getCurrentNotification", {
    immediate: true,
  })
  currentNotifChanged(
    newNotif: notificationType | null,
    oldNotif: notificationType | null | undefined
  ): void {
    // console.log("Current Notif Changed", newNotif, oldNotif);
    if (newNotif == null) {
      this.open = false;
      this.currentNotif = newNotif;
      setTimeout(() => {
        this.open = true;
      }, 200);
    } else {
      this.open = false;
      this.currentNotif = newNotif;
      setTimeout(() => {
        this.open = true;
      }, 200);
    }
  }
}
</script>
