<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="11"
        ><v-tabs-items v-model="tab">
          <v-tab-item v-for="item in items" :key="item.step">
            <v-card
              :min-height="$vuetify.breakpoint.xs ? '150px' : '350px'"
              flat
              class="d-flex align-center"
            >
              <v-img
                max-height="400px"
                min-height="230px"
                width="100%"
                content-class="imgOverlay"
                :src="item.img"
              ></v-img>
              <div
                :class="$vuetify.breakpoint.smAndUp ? 'text-h3' : 'text-body-1'"
                class="white--text text-center centered"
              >
                {{ item.text }}
              </div>
            </v-card>
            <!-- <div class="carouselImg"></div> -->
          </v-tab-item>
        </v-tabs-items></v-col
      >
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs
          :vertical="$vuetify.breakpoint.xs"
          mobile-breakpoint="0"
          v-model="tab"
        >
          <v-tabs-slider color="blue"></v-tabs-slider>

          <v-tab v-for="item in items" :key="item.step">
            <div class="text-break">{{ item.step }}</div>
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class Carousel extends Vue {
  tab = null;
  items = [
    {
      step: "Compliance with the Firearms",
      img: require("@/assets/slider2.png"),
      text: "Compliance with the Firearms Control Act 60 of 2000 can sometimes feel like a nightmare. Over and above this you need to rely on employees to manage this process on excel spreadsheets and a mountain of paper registers.",
    },
    {
      step: "Zero Eight Firearm Compliance",
      img: require("@/assets/slider3.png"),
      text: "Zero Eight Firearm Compliance are experienced in assisting security companies improve their security officer and firearm compliance, through online compliance visibility and a built-in process flow. Using Zero Eight helps security companies gain total control of firearm compliance, by speeding up and enhancing the management process.",
    },
  ];
}
</script>
<style lang="scss">
.centered {
  width: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.v-tabs-slider-wrapper {
  top: 0;
}

.imgOverlay {
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
