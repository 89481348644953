<template>
  <v-card :img="require('@/assets/footerbanner.png')" class="pt-4 pb-10">
    <div class="text-h1 white--text text-center mt-5">INFO@ZEROEIGHT.CO.ZA</div>

    <v-container class="mt-10">
      <!-- Contact Us -->
      <v-row justify="center">
        <v-col cols="10"
          ><v-text-field
            v-model="name"
            hide-details="auto"
            solo
            label="Name *"
          ></v-text-field
        ></v-col>
        <!-- <v-col cols="10"
          ><v-text-field
            v-model="email"
            hide-details="auto"
            solo
            label="Email *"
          ></v-text-field
        ></v-col> -->
        <v-col cols="10"
          ><v-text-field
            hide-details="auto"
            v-model="subject"
            solo
            label="Subject *"
          ></v-text-field
        ></v-col>
        <v-col cols="10"
          ><v-textarea
            v-model="message"
            hide-details="auto"
            solo
            label="Message *"
          ></v-textarea
        ></v-col>
      </v-row>
      <v-row>
        <v-col offset="1" cols="auto"
          ><v-btn color="primary" :href="getLink">Submit</v-btn></v-col
        >
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class FooterBanner extends Vue {
  subject = "";
  email = "";
  message = "";
  name = "";

  get getLink() {
    return `mailto: info@zeroeight.co.za?subject=Feedback: ${this.subject}&body=${this.message}%0A%0A${this.name}`;
  }
}
</script>
