<template>
  <v-container class="py-8">
    <v-row dense justify="center">
      <v-col cols="10"
        ><div class="text-uppercase text-h1 text-center">
          FREQUENTLY ASKED QUESTIONS
        </div></v-col
      >
      <v-col cols="10"
        ><div class="text-body-1 text-center">
          Answer all of your questions
        </div></v-col
      >
    </v-row>
    <v-row justify="center">
      <v-col cols="10">
        <v-expansion-panels>
          <v-expansion-panel
            v-for="item in faq"
            :key="item.question"
            class="my-2"
          >
            <v-expansion-panel-header class="px-2"
              ><div>
                <span class="px-2">&#8226;</span>{{ item.question }}
              </div></v-expansion-panel-header
            >
            <v-expansion-panel-content
              class="py-0"
              style="border-top: 1px solid grey"
              ><div class="py-3">{{ item.answer }}</div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class FAQ extends Vue {
  faq = [
    {
      question: "What does the software do?",
      answer:
        "Zero Eight is an online solution that allows your Security Company to manage firearm compliance and eliminate files and spreadsheets.",
    },
    {
      question: "What are some of the features available?",
      answer:
        "Armed officer compliance register, firearm register, ammunition register, incidents, permit register, reports and alerts.",
    },
    {
      question: "Can I issue firearm permits?",
      answer:
        "Yes, there is no need to use manual permit books anymore. However, permits is a small part of what this solution offers.",
    },
    {
      question: "Are electronic permits lawful?",
      answer:
        "Yes! Electronic permits are totally legal and comply with the Firearms Control Act.",
    },
    {
      question: "Do I need to purchase the software",
      answer:
        "No, the software is offered at an affordable monthly license fee.",
    },
  ];
}
</script>
