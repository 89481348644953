<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" lg="6">
        <div>NOtify {{ $route.params }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class NotifySub extends Vue {}
</script>
