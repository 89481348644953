<template>
  <v-footer color="white" class="pa-0">
    <v-container fluid>
      <v-row class="white" align="center"
        ><v-col cols="12">
          <v-row justify="center" justify-md="start" align="center">
            <v-col cols="12" sm="auto"
              ><v-img
                contain
                src="@/assets/logo.png"
                alt="ZeroEight"
                class="mt-1 mx-auto"
                :height="$vuetify.breakpoint.smAndDown ? '100%' : '70px'"
                :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '140px'"
                min-width="70"
                min-height="30"
            /></v-col>
            <v-col cols="auto" class="text-center">
              <v-btn
                text
                active-class="rounded"
                v-for="item in menuItems"
                :key="item.title"
                :to="{
                  hash:
                    item.path.includes('#') == false ? undefined : item.path,
                  name: item.path.includes('#') == true ? 'home' : item.path,
                }"
                rounded
                class="text-none py-1 mx-1"
              >
                <!-- <v-icon left dark>{{ item.icon }}</v-icon> -->
                {{ item.title }}
              </v-btn>
              <v-btn
                text
                height="30"
                active-class="rounded"
                rounded
                class="text-none py-1 mx-1 info--text"
                href="https://app.zeroeight.co.za/"
                target="blank"
              >
                Zero8 App
              </v-btn></v-col
            >
            <v-spacer class="hidden-sm-and-down"></v-spacer>
            <v-col cols="auto"
              ><v-btn
                icon
                class="ma-1"
                :href="item.path"
                target="_blank"
                outlined
                v-for="item in socialItems"
                :key="item.title"
                ><v-icon v-text="item.icon"></v-icon></v-btn
            ></v-col> </v-row></v-col
      ></v-row>
    </v-container>
    <v-container fluid class="grey lighten-3">
      <v-row justify="space-between" align="center" dense
        ><v-col cols="12" sm="auto"
          ><div class="text-body-1 text-center">
            Copyright 2021 — Zeroeight. All rights reserved.
          </div></v-col
        >
        <v-col cols="12" sm="auto" class="text-center">
          <v-row justify="space-between" align="center" dense>
            <v-col cols="auto">
              <!-- <a
                class="secondary--text text-body-2 py-1 mx-1"
                target="_blank"
                href="/files/ZeroEightPrivacyPolicy.pdf"
              >
                //<v-icon left dark>{{ item.icon }}</v-icon>
                Privacy Policy
              </a> -->
              <v-btn
                :to="{ name: 'privacyPolicy' }"
                small
                text
                class="text-none text-decoration-underline text-body-2"
              >
                Privacy Policy
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <!-- <a
                class="secondary--text text-body-2 py-1 mx-1"
                target="_blank"
                href="/files/ZeroEightPrivacyPolicy.pdf"
              >
                //<v-icon left dark>{{ item.icon }}</v-icon>
                Terms and Conditions
              </a> -->

              <v-btn
                :to="{ name: 'privacyPolicy' }"
                small
                text
                class="text-none text-decoration-underline text-body-2"
              >
                Terms and Conditions
              </v-btn>
            </v-col>
          </v-row>
        </v-col></v-row
      >
    </v-container>
  </v-footer>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";

@Component
export default class Footer extends Vue {
  get sidebar() {
    return Nav.getSidebar;
  }
  set sidebar(val: boolean) {
    Nav.setSideBar(val);
  }
  get menuItems() {
    return Nav.getMenuItems;
  }
  socialItems = [
    {
      title: "Facebook",
      path: "https://www.facebook.com/FirearmCompliance/",
      icon: "mdi-facebook",
    },
  ];
  footerItems = [
    {
      title: "Privacy Policy",
      path: "#privacy",
      icon: "mdi-account",
    },
    {
      title: "Terms",
      path: "#terms",
      icon: "mdi-account",
    },
  ];
}
</script>
