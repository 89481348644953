import Storage from "./Storage";
import Subscription from "./Subscription";
import { notifications } from "./notifications";

const storage = Storage();
const subscription = Subscription();
export {
  storage as Storage,
  subscription as Subscription,
  notifications as NotificationLib,
};
