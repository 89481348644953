import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action,
} from "vuex-module-decorators";
import {
  UserDetails,
  UserDetailsResponse,
  userRole,
  User as UserModel,
} from "models";
import store from "@/store";
import { Storage } from "@/helpers";
import router from "@/router";

export interface login {
  [field: string]: string | null;
  email: string | null;
  password: string | null;
}

@Module({
  dynamic: true,
  namespaced: true,
  name: "User",
  store,
})
class User extends VuexModule {
  userDetails: UserDetails | null = {
    expiration: null,
    accessToken: null,
    username: null,
  };
  user: UserModel | null = null;
  roles: userRole[] = [];

  // get getAllowedRoutes(): Record<number, string[]> {
  //   return this.allowedRoutes;
  // }
  @Mutation
  setUser(user: UserModel | null) {
    this.user = user;
  }
  get getUser(): UserModel | null {
    return this.user;
  }
  get getUserRoles(): userRole[] {
    return this.roles;
  }
  @Mutation
  setUserRoles(role: userRole[]) {
    this.roles = role;
  }
  @Mutation
  setUserDetails(details: UserDetailsResponse | null) {
    this.userDetails = details;
    if (details) {
      Storage.setLogin(details);
    } else {
      Storage.removeLogin();
    }
  }

  @Mutation
  removeLoggedIn() {
    Storage.removeLogin();
    this.userDetails = null;
    this.roles = [];
    const requiresAuth = router.currentRoute.matched.some(
      (route) => route.meta && route.meta.requiresAuth
    );
    console.log("Logging out ", requiresAuth);
    if (requiresAuth == true) {
      router.push({
        name: "home",
      });
    }
  }
  get getLoggedIn() {
    //add time check here for timestamp?
    //check accessToken
    return this.userDetails;
  }
  get isLoggedIn(): boolean {
    //add time check here for timestamp?
    //check accessToken
    return this.userDetails != null;
  }
}
export default getModule(User);
