var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app-bar',{staticClass:"py-3",attrs:{"clipped-right":"","app":"","color":"white","light":"","height":"100"}},[_c('v-toolbar-title',[_c('router-link',{staticStyle:{"cursor":"pointer"},attrs:{"to":"/","tag":"span"}},[_c('v-img',{staticClass:"mt-1",attrs:{"contain":"","src":require("@/assets/logo.png"),"alt":"ZeroEight","height":"50px","max-width":"140","min-width":"70","min-height":"30"}})],1)],1),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down",staticStyle:{"height":"40px"}},[_vm._l((_vm.menuItems),function(item){return [(
          item.title == 'Subscription'
            ? _vm.subStore.getTrial &&
              _vm.subStore.getTrial?.status != _vm.Status.PAYFASTPASS
            : false
        )?_c('v-badge',{key:item.title,staticClass:"subBadge",attrs:{"color":_vm.subStore.getTrial?.status == _vm.Status.ACTIVE
            ? 'success'
            : _vm.subStore.getTrial?.status == _vm.Status.EXPIRED
            ? 'warning'
            : 'grey',"overlap":"","content":_vm.subStore.getTrial?.status == _vm.Status.EXPIRED
            ? 'Expired Trial'
            : _vm.subStore.getTrial?.status == _vm.Status.ACTIVE
            ? 'Active Trial'
            : 'Checking',"offset-y":"10","offset-x":"50"}},[_c('v-btn',{staticClass:"text-none py-1 mx-1",attrs:{"text":"","height":"40","active-class":"rounded","to":{
            hash: item.path.includes('#') == false ? undefined : item.path,
            name: item.path.includes('#') == true ? 'home' : item.path,
          }}},[_vm._v(" "+_vm._s(item.title)+" ")])],1):_c('v-btn',{key:item.title,staticClass:"text-none py-1 mx-1",attrs:{"text":"","height":"40","active-class":"rounded","to":{
          hash: item.path.includes('#') == false ? undefined : item.path,
          name: item.path.includes('#') == true ? 'home' : item.path,
        },"rounded":""}},[_vm._v(" "+_vm._s(item.title)+" ")])]}),_c('v-btn',{staticClass:"text-none py-1 mx-1 info--text",attrs:{"text":"","height":"30","active-class":"rounded","rounded":"","href":"https://app.zeroeight.co.za/","target":"blank"}},[_vm._v(" Zero8 App ")])],2),_c('v-spacer'),(_vm.getAuth != null)?_c('span',[_c('v-menu',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account")])],1)]}}],null,false,1415903684)},[_c('v-list',{attrs:{"min-width":150}},[_c('v-list-item',[_c('v-list-item-content',[_c('span',[_vm._v(_vm._s(_vm.getAuth))])])],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('span',[_vm._v("Logout")])])],1)],1)],1)],1):_vm._e(),_c('span',{staticClass:"justify-center hidden-sm-and-down"},[(_vm.getAuth == null)?[_c('v-btn',{staticClass:"ma-2",attrs:{"width":"80","to":{
          name: 'register',
        },"outlined":"","rounded":""}},[_vm._v("Register")]),_c('v-btn',{staticClass:"ma-2",attrs:{"width":"80","to":{
          name: 'login',
        },"outlined":"","rounded":""}},[_vm._v("Login")])]:_vm._e(),_c('v-btn',{staticClass:"ma-2",attrs:{"width":"80","outlined":"","to":{
        name: 'home',
        hash: '#faq',
      },"rounded":""}},[_vm._v("FAQ")])],2),_c('span',{staticClass:"hidden-md-and-up"},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.sidebar = !_vm.sidebar}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }