import { render, staticRenderFns } from "./NotifySub.vue?vue&type=template&id=63cb0d80"
import script from "./NotifySub.vue?vue&type=script&lang=ts"
export * from "./NotifySub.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports