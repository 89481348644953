<template>
  <v-container fluid class="pa-0">
    <Carousel id="home"></Carousel>
    <Services class="my-16" id="services"></Services>
    <AboutUs class="my-16" id="about-us"></AboutUs>
    <!-- <Tutorials v-if="getLoggedIn" class="my-16" id="tutorials"></Tutorials> -->
    <Features class="my-16" id="features"></Features>
    <Testimonials class="my-16" id="testimonials"></Testimonials>
    <Prices id="pricing"></Prices>
    <FAQ class="my-16" id="faq"></FAQ>
    <Feedback id="feedback"></Feedback>
    <Footer></Footer>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Footer from "@/components/Footer.vue";
import Feedback from "@/components/Feedback.vue";
import FAQ from "@/components/FAQ.vue";
import Features from "@/components/Features.vue";
import AboutUs from "@/components/AboutUs.vue";
import Tutorials from "@/components/Tutorials.vue";
import Services from "@/components/Services.vue";
import Carousel from "@/components/Carousel.vue";
import Testimonials from "@/components/Testimonials.vue";
import Prices from "@/components/Prices.vue";
import Auth from "@/store/modules/Auth";
import { Subscription } from "@/helpers";

@Component({
  components: {
    Footer,
    Feedback,
    FAQ,
    Features,
    AboutUs,
    Tutorials,
    Services,
    Carousel,
    Testimonials,
    Prices,
  },
})
export default class MainView extends Vue {
  async mounted() {
    try {
      await Subscription.getSubscription();
    } catch (err) {
      console.log("Cannot init: ", err);
    }
  }
  get getLoggedIn() {
    return Auth.isLoggedIn;
  }
}
</script>
