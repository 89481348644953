<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10" sm="8"
        ><v-card>
          <v-card-title class="primary">
            <div class="text-h2 text-center white--text">Register</div>
          </v-card-title>
          <v-card-text class="px-2">
            <v-form v-model="valid">
              <v-container>
                <v-row justify="center">
                  <v-col cols="12"
                    ><div class="text-h4 text-center">
                      Register to purcase packages for your team
                    </div></v-col
                  >
                  <v-col cols="12" class="text-h5">Company details</v-col>
                  <v-col cols="12" md="6"
                    ><v-text-field
                      outlined
                      hide-details="auto"
                      :rules="[rules.required]"
                      v-model="submit.securityFirm.companyName"
                      label="Company Name"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" md="6"
                    ><v-file-input
                      outlined
                      prepend-icon=""
                      append-icon="mdi-attachment"
                      show-size
                      :rules="[rules.required]"
                      hide-details="auto"
                      v-model="submit.logo"
                      label="Company Logo"
                    ></v-file-input
                  ></v-col>
                  <v-col cols="12" md="6"
                    ><v-text-field
                      outlined
                      :rules="[rules.required]"
                      hide-details="auto"
                      v-model="submit.securityFirm.registrationNumber"
                      label="Registration Number"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" md="6"
                    ><v-menu
                      ref="expiryMenu"
                      v-model="expiryDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          append-icon="mdi-calendar"
                          v-model="submit.expiryDate"
                          label="Expiry Date"
                          outlined
                          :rules="[rules.required]"
                          hide-details="auto"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :active-picker.sync="activePicker"
                        v-model="submit.expiryDate"
                        @change="save"
                        min="2020-01-01"
                        max="2030-01-01"
                      ></v-date-picker> </v-menu
                  ></v-col>
                  <!-- <v-col cols="12" md="6"
                    ><v-text-field
                      outlined
                      hide-details="auto"
                      :rules="[rules.required]"
                      v-model="submit.securityFirm.contactPerson"
                      label="Contact Person"
                    ></v-text-field
                  ></v-col> -->
                  <v-col cols="12" md="6"
                    ><v-text-field
                      outlined
                      hide-details="auto"
                      :rules="[rules.required]"
                      v-model="submit.securityFirm.contactNumber"
                      label="Company Number"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" md="6"
                    ><v-textarea
                      rows="4"
                      outlined
                      hide-details="auto"
                      :rules="[rules.required]"
                      v-model="submit.securityFirm.companyAddress"
                      label="Company Address"
                    ></v-textarea
                  ></v-col>
                  <v-col cols="12" class="text-h5">Security Firm Admin</v-col>
                  <v-col cols="12" md="6"
                    ><v-text-field
                      outlined
                      hide-details="auto"
                      :rules="[rules.required]"
                      v-model="submit.securityFirm.name"
                      label="Name"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" md="6"
                    ><v-text-field
                      outlined
                      hide-details="auto"
                      :rules="[rules.required]"
                      type="email"
                      v-model="submit.securityFirm.surname"
                      label="Surname"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12"
                    ><v-text-field
                      outlined
                      hide-details="auto"
                      :rules="[rules.required]"
                      type="email"
                      v-model="submit.userName"
                      label="Email"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" md="6"
                    ><v-text-field
                      outlined
                      na
                      autocomplete="new-password"
                      @click:append="reveal = !reveal"
                      :append-icon="reveal ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="reveal ? 'text' : 'password'"
                      error-count="3"
                      :rules="[
                        rules.required,
                        rules.special,
                        rules.uppercase,
                        rules.lowercase,
                        rules.digit,
                        rules.passwordLength,
                      ]"
                      hide-details="auto"
                      v-model="submit.password"
                      label="Password"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="12" md="6"
                    ><v-text-field
                      outlined
                      autocomplete="confirm-new-password"
                      @click:append="reveal = !reveal"
                      :append-icon="reveal ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="reveal ? 'text' : 'password'"
                      :rules="[rules.required, getMatchingPassword]"
                      v-model="confirmPassword"
                      hide-details="auto"
                      label="Confirm Password"
                    ></v-text-field
                  ></v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-row justify="center">
                <v-col cols="12">
                  <v-alert
                    color="error"
                    dark
                    width="100%"
                    icon="mdi-alert-circle"
                    prominent
                    v-model="error.open"
                  >
                    <div class="text-body-1">
                      {{ error.message }}
                    </div>
                  </v-alert>
                  <v-btn
                    block
                    :disabled="!valid"
                    color="primary"
                    @click="addSecurityFirm"
                    >Register</v-btn
                  ></v-col
                >
                <v-col cols="7"
                  ><v-btn block text @click="goToHomepage"
                    >Back to homepage</v-btn
                  ></v-col
                >
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card></v-col
      >
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import { fileToBase64 } from "@/helpers/File";
import Nav from "@/store/modules/Nav";
import { axiosInstance, axiosNoAuth } from "@/plugins/axios";
import Auth from "@/helpers/Authenticate";
import axios, { AxiosError } from "axios";
import Subscription from "@/helpers/Subscription";
const authHook = Auth();
const subscriptionHook = Subscription();

interface secFirm {
  ID: number;
  UserId: string;
  RegistrationNumber: string;
  CompanyName: string;
  ContactNumber: string;
  CreatedBy: string;
  UpdatedBy: string;
  UpdatedDate: string;
  user?: string;
  IsActive: boolean;
  CompanyAddress: string;
  ContactPerson: string;
  Surname: string;
  Name: string;
  ExpiryDate: string;
  AROLimit: number;
  SGLimit: number;
  CompanyLogo: string;
}
interface secFirmSubmit {
  surname: string;
  name: string;
  registrationNumber: string;
  companyName: string;
  contactNumber: string;
  companyAddress: string;
}
interface submitRegister {
  securityFirm: secFirmSubmit;
  userName: string;
  password: string;
  expiryDate: string;
  logo: File | null;
}
@Component
export default class Register extends Vue {
  // TODO: rules for field
  valid = false;
  expiryDate = false;
  activePicker: string | null = null;
  loading = false;
  confirmPassword = "";
  reveal = false;
  error = {
    open: false,
    message: "",
  };
  submit: submitRegister = {
    securityFirm: {
      registrationNumber: "",
      companyName: "",
      contactNumber: "",
      companyAddress: "",
      name: "",
      surname: "",
    },

    expiryDate: "",
    userName: "",
    password: "",
    logo: null,
  };
  goToHomepage() {
    this.$router.push({
      name: "home",
    });
  }
  get getMatchingPassword() {
    return (
      this.submit.password == this.confirmPassword || "Passwords do not match"
    );
  }
  rules = {
    passwordLength: (value: string): boolean | string =>
      value.length >= 8 || "Password should be 8 characters or longer",
    required: (value: string): boolean | string =>
      !!value || "This field is Required.",
    uppercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[A-Z])/;
      return pattern.test(value) || "Requires atleast 1 uppercase letter";
    },
    lowercase: (value: string): boolean | string => {
      const pattern = /^(?=.*[a-z])/;
      return pattern.test(value) || "Requires atleast 1 lowercase letter";
    },
    digit: (value: string): boolean | string => {
      const pattern = /^(?=.*\d)/;
      return pattern.test(value) || "Requires atleast 1 digit";
    },
    special: (value: string): boolean | string => {
      const pattern = /^(?=.*\W)/;
      return pattern.test(value) || "Requires atleast 1 special character";
    },
    /*  matchingPasswords: (value: string): boolean | string =>
      this.checkPasswordsMatch(value) || "Passwords do not match", */
  };
  async addSecurityFirm() {
    try {
      this.loading = true;
      if (this.submit.logo) {
        debugger;
        const log = await fileToBase64(this.submit.logo);
        console.log("Test logo ", log);
        const res = await axiosNoAuth.post(
          "/api/securityfirmapi/addSecurityFirm",
          {
            securityFirm: this.submit.securityFirm,
            userName: this.submit.userName,
            password: this.submit.password,
            expiryDate: this.submit.expiryDate,
            logo: log,
          }
        );
        const secId = res.data.securityFirmId as number;

        // TODO auto sign in after register.
        console.log("Register response", res.data);
        await authHook.loginFull(res.data.username, res.data.password);
        if (secId && !Number.isNaN(secId)) {
          await subscriptionHook.startTrial(secId);
        }
        await await this.$router
          .push({
            name: "home",
            hash: "#pricing",
          })
          .catch((err) => {
            console.log("Error navigating to dash...", err);
          });
      }
    } catch (err) {
      console.log("Error adding security firm: ", err);
      if (axios.isAxiosError(err)) {
        const temp = err as AxiosError;
        if (temp.response && temp.response.data) {
          if ((temp.response.data as any).status) {
            this.error.message = "";
            this.error.message =
              (temp.response.data as any).status +
              ": " +
              (temp.response.data as any).title;
            this.error.open = true;
            return;
          }

          this.error.message =
            temp.response.status + ": " + temp.response.statusText;
          this.error.open = true;
        } else {
          this.error.message = temp.code + ": " + temp.message;
          this.error.open = true;
        }
      }
    } finally {
      this.loading = false;
    }
  }
  @Watch("expiryDate")
  onExpiryChanged(val: boolean) {
    val && setTimeout(() => (this.activePicker = "YEAR"));
  }
  save(date: string) {
    if (this.$refs.expiryMenu) {
      (this.$refs.expiryMenu as any).save(date);
    }
  }
}
</script>
