<template>
  <v-app-bar clipped-right app color="white" light class="py-3" height="100">
    <v-toolbar-title>
      <router-link to="/" tag="span" style="cursor: pointer">
        <!-- <v-img
            contain
            src="@/assets/logo.png"
            alt="ZeroEight"
            class="mt-8 hidden-sm-and-down"
          /> -->
        <v-img
          contain
          src="@/assets/logo.png"
          alt="ZeroEight"
          class="mt-1"
          height="50px"
          max-width="140"
          min-width="70"
          min-height="30"
        />
      </router-link>
    </v-toolbar-title>
    <v-toolbar-items style="height: 40px" class="hidden-sm-and-down">
      <template v-for="item in menuItems">
        <v-badge
          class="subBadge"
          v-if="
            item.title == 'Subscription'
              ? subStore.getTrial &&
                subStore.getTrial?.status != Status.PAYFASTPASS
              : false
          "
          :color="
            subStore.getTrial?.status == Status.ACTIVE
              ? 'success'
              : subStore.getTrial?.status == Status.EXPIRED
              ? 'warning'
              : 'grey'
          "
          overlap
          :content="
            subStore.getTrial?.status == Status.EXPIRED
              ? 'Expired Trial'
              : subStore.getTrial?.status == Status.ACTIVE
              ? 'Active Trial'
              : 'Checking'
          "
          :key="item.title"
          offset-y="10"
          offset-x="50"
        >
          <v-btn
            text
            height="40"
            active-class="rounded"
            :to="{
              hash: item.path.includes('#') == false ? undefined : item.path,
              name: item.path.includes('#') == true ? 'home' : item.path,
            }"
            class="text-none py-1 mx-1"
          >
            <!-- <v-icon left dark>{{ item.icon }}</v-icon> -->

            {{ item.title }}
          </v-btn>
        </v-badge>
        <v-btn
          v-else
          text
          height="40"
          active-class="rounded"
          :to="{
            hash: item.path.includes('#') == false ? undefined : item.path,
            name: item.path.includes('#') == true ? 'home' : item.path,
          }"
          rounded
          class="text-none py-1 mx-1"
          :key="item.title"
        >
          <!-- <v-icon left dark>{{ item.icon }}</v-icon> -->

          {{ item.title }}
        </v-btn>
      </template>

      <v-btn
        text
        height="30"
        active-class="rounded"
        rounded
        class="text-none py-1 mx-1 info--text"
        href="https://app.zeroeight.co.za/"
        target="blank"
      >
        Zero8 App
      </v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <span v-if="getAuth != null"
      ><v-menu left>
        <template v-slot:activator="{ on, attrs }"
          ><v-btn v-bind="attrs" v-on="on" icon
            ><v-icon>mdi-account</v-icon></v-btn
          ></template
        ><v-list :min-width="150">
          <v-list-item>
            <v-list-item-content>
              <span>{{ getAuth }}</span>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <span>Logout</span>
            </v-list-item-content>
          </v-list-item>
        </v-list></v-menu
      ></span
    >
    <span class="justify-center hidden-sm-and-down">
      <template v-if="getAuth == null">
        <v-btn
          width="80"
          :to="{
            name: 'register',
          }"
          outlined
          rounded
          class="ma-2"
          >Register</v-btn
        >
        <v-btn
          width="80"
          :to="{
            name: 'login',
          }"
          outlined
          rounded
          class="ma-2"
          >Login</v-btn
        > </template
      ><v-btn
        width="80"
        outlined
        :to="{
          name: 'home',
          hash: '#faq',
        }"
        rounded
        class="ma-2"
        >FAQ</v-btn
      >
    </span>
    <span class="hidden-md-and-up">
      <v-app-bar-nav-icon @click="sidebar = !sidebar"></v-app-bar-nav-icon>
    </span>
  </v-app-bar>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import Nav from "@/store/modules/Nav";
import Auth from "@/store/modules/Auth";
import Subscription from "@/store/modules/Subscription";
import { SubscriptionTrialStatus } from "@/enum/trialRun";

@Component
export default class Appbar extends Vue {
  Status = SubscriptionTrialStatus;
  subStore = Subscription;
  logout() {
    Auth.removeLoggedIn();
    Subscription.setSubscription(null);
  }
  get getAuth() {
    if (Auth.getLoggedIn) {
      return Auth.getLoggedIn.username;
    }
    return null;
  }
  get sidebar() {
    return Nav.getSidebar;
  }
  set sidebar(val: boolean) {
    Nav.setSideBar(val);
  }
  get menuItems() {
    return Nav.getMenuItems;
  }
}
</script>
<style>
.v-badge__badge {
  font-size: 10px !important;
}
</style>
