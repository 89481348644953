<template>
  <v-app>
    <Navbar></Navbar>
    <Appbar></Appbar>
    <GlobalNotifications></GlobalNotifications>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import { Component, Vue, Watch } from "vue-property-decorator";
import Appbar from "@/components/Appbar.vue";
import Navbar from "@/components/Navigation.vue";
import GlobalNotifications from "@/components/GlobalNotifications.vue";

@Component({
  components: {
    Appbar,
    Navbar,
    GlobalNotifications,
  },
})
export default class App extends Vue {
  // mounted() {
  //   if (Auth.isLoggedIn) {
  //   }
  // }
}
</script>
<style>
body {
  min-width: 300px;
}
.massiveHeader {
  font-size: 48px;
}
</style>
